import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, Autocomplete, TextField } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import xPosApiRequest from "api/xPosApiRequest";
import { isOk } from "views/admin/xpos/_helpers/requestHelpers";
import XposCollapse from "../../shared/XposCollapse";

function ItemCustomConfiguration({
  showNotificationError,
  showNotificationSuccess,
  setLoading,
  data,
  organizationConfigGuid,
  country,
}) {
  const [openSectionState, setOpenSectionState] = useState(false);
  const [formElementsValues, setFormElementsValues] = useState({});
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  useEffect(() => {
    // Inicializa formElementsValues con los valores de data
    const initialValues = {};
    data?.sections?.forEach((section) => {
      section?.fields?.forEach((field) => {
        if (!initialValues[data.key]) {
          initialValues[data.key] = {};
        }
        initialValues[data.key][field.element.name] = field.element.value;
      });
    });
    setFormElementsValues(initialValues);
  }, [data]);

  const handleSetFormValues = (idForm, fieldName, value) => {
    setFormElementsValues((prevState) => ({
      ...prevState,
      [idForm]: {
        ...prevState[idForm],
        [fieldName]: value,
      },
    }));
  };

  const handleUpdateXpos = async () => {
    setLoading(true);
    const body = {
      configuration: String(JSON.stringify(formElementsValues)),
      user: userInfo.correo,
    };
    const options = {
      method: "PUT",
      url: organizationConfigGuid
        ? "/configuration/xpos-update"
        : "/global-configuration/xpos-update",
      headers: {
        "Content-Type": "application/json",
        ...(organizationConfigGuid ? { organizationConfigGuid } : { countryId: country }),
      },
      data: body,
    };
    try {
      const response = await xPosApiRequest(options);
      if (isOk(response.status)) {
        showNotificationSuccess("Se actualizó exitosamente");
      } else {
        const errorMessage =
          response.data && response.data.length < 100
            ? response.data
            : "Ocurrió un error al actualizar.";
        showNotificationError(errorMessage);
      }
    } catch (error) {
      showNotificationError(`Ocurrio un error al actualizar`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <XposCollapse
      title={data.title}
      open={openSectionState}
      onClick={() => setOpenSectionState(!openSectionState)}
    >
      <Grid container spacing={4} pb={2} px={1}>
        {data.subTitle && (
          <Grid item xs={12}>
            <MDTypography fontWeight="bold" fontSize="14px" color="dark">
              {data.subTitle}
            </MDTypography>
          </Grid>
        )}
        {data.sections.map((item) => (
          <>
            <Grid item xs={6}>
              <MDTypography fontWeight="bold" fontSize="14px" color="dark">
                {item.description}
              </MDTypography>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={4}>
                {item.fields.map((field) => (
                  <Grid item xs={12}>
                    {field.element.type === "select" ? (
                      <Autocomplete
                        options={field.element.options.map((option) => option.value)}
                        value={
                          formElementsValues?.[data.key]?.[field.element.name] ??
                          field?.element?.value ??
                          null
                        }
                        onChange={(event, newValue) =>
                          handleSetFormValues(data.key, field.element.name, newValue)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            placeholder="Seleccione"
                            fullWidth
                            required={JSON.parse(field.element.validation).required}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    ) : (
                      <MDInput
                        label={field.label}
                        type={field.type}
                        min={JSON.parse(field.element.validation).min}
                        max={JSON.parse(field.element.validation).max}
                        size="medium"
                        variant="outlined"
                        fullWidth
                        required={JSON.parse(field.element.validation).required}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                          handleSetFormValues(data.key, field.element.name, e.target.value);
                        }}
                        value={field?.element?.value}
                      />
                    )}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </>
        ))}
        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <Grid
            backgroundColor="#099268"
            width="110px"
            borderRadius={5}
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => {
              handleUpdateXpos();
            }}
          >
            <MDTypography
              fontWeight="bold"
              color="white"
              py={1}
              style={{ marginLeft: 5, paddingRight: 5, fontSize: 12 }}
            >
              {data.actionDescription}
            </MDTypography>
          </Grid>
        </Grid>
      </Grid>
    </XposCollapse>
  );
}

ItemCustomConfiguration.defaultProps = {
  organizationConfigGuid: "",
  data: {},
  country: "",
};

ItemCustomConfiguration.propTypes = {
  showNotificationError: PropTypes.func.isRequired,
  showNotificationSuccess: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  data: PropTypes.shape({
    key: PropTypes.string,
    type: PropTypes.number,
    sections: PropTypes.array,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    actionDescription: PropTypes.string,
  }),
  organizationConfigGuid: PropTypes.string,
  country: PropTypes.string,
};
export default ItemCustomConfiguration;
