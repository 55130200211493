import useCreateOrganizationCL from "./useCreateOrganizationCL";
import useCreateOrganizationGT from "./useCreateOrganizationGT";
import useCreateOrganizationPY from "./useCreateOrganizationPY";

const createOrganizationByCountry = ({ country, organization, showNotificationError }) => {
  const createOrganizationCL = useCreateOrganizationCL({ organization });
  const createOrganizationPY = useCreateOrganizationPY({ organization });
  const createOrganizationGT = useCreateOrganizationGT({ organization, showNotificationError });

  const array = {
    cl: createOrganizationCL,
    py: createOrganizationPY,
    gt: createOrganizationGT,
  };

  return array[country];
};

export default createOrganizationByCountry;
