import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import xPosApiRequest from "api/xPosApiRequest";
import BKModal from "components/BKModal";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import EditIcon from "@mui/icons-material/Edit";

import { isOk } from "views/admin/xpos/_helpers/requestHelpers";

function ModalBranches({
  modalBranch,
  setModalBranch,
  organizationGuid,
  showNotificationError,
  showNotificationSuccess,
  setLoading,
}) {
  const [branches, setBranches] = useState([]);
  const [inputVal, setInputVal] = useState("");
  const [branchGuid, setBranchGuid] = useState("");

  const fetchBranch = async () => {
    setLoading(true);
    const options = {
      method: "GET",
      url: `/branches/organization/${organizationGuid}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await xPosApiRequest(options);
      if (isOk(response.status)) {
        const branchesData = await response.data;
        setBranches(branchesData);
      } else {
        showNotificationError("Ocurrio un error al cargar las Sucursales.");
      }
    } catch (error) {
      showNotificationError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (organizationGuid) {
      fetchBranch();
    }
  }, [organizationGuid]);

  const handleEdit = (branch) => {
    setInputVal(branch.name);
    setBranchGuid(branch.guid);
  };

  const onChange = (e) => {
    setInputVal(e.target.value);
  };

  const closeModal = () => {
    setModalBranch(false);
    setInputVal("");
    setBranchGuid("");
  };

  const saveDataModal = async () => {
    const method = branchGuid ? "PUT" : "POST";
    const urlSuffix = branchGuid ? `/${branchGuid}` : "";
    const params = new URLSearchParams({ name: inputVal });

    if (!branchGuid) {
      params.append("organizationGuid", organizationGuid);
    }

    const options = {
      method,
      url: `/branches${urlSuffix}?${params.toString()}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    setLoading(true);
    try {
      const response = await xPosApiRequest(options);
      if (isOk(response.status)) {
        const branchesData = await response.data;
        const branchData = branchesData.value;

        setBranches((prevItems) => {
          if (branchGuid) {
            return prevItems.map((item) => (item.guid === branchData.guid ? branchData : item));
          }
          return [...prevItems, branchData];
        });

        showNotificationSuccess(
          `Se ${branchGuid ? "editó" : "creó"} la sucursal satisfactoriamente`
        );
        closeModal();
      } else {
        showNotificationError("Ocurrio un error al cargar las Sucursales.");
      }
    } catch (error) {
      showNotificationError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BKModal
      closeModal={closeModal}
      saveChanges={saveDataModal}
      open={modalBranch}
      title="Sucursales"
      titleButton={branchGuid ? "Editar" : "Guardar"}
      maxWidth="xs"
      styleModal={{
        titleColor: "inherit",
        acceptButtonColor: "success",
        cancelButtonColor: "error",
        variantButton: "gradient",
      }}
      sxButton={{ textTransform: "none", borderRadius: "20px" }}
    >
      <Grid container spacing={3} mt={0}>
        <Grid item xs={12} md={12}>
          <MDInput
            label="Nombre de sucursal"
            placeholder="Ingrese"
            size="medium"
            variant="outlined"
            type="text"
            fullWidth
            required
            InputLabelProps={{ shrink: true }}
            inputProps={{ maxLength: 100 }}
            value={inputVal}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <List style={{ width: "100%", maxHeight: "250px", overflow: "auto" }}>
            {branches.map((branch) => (
              <ListItem divider="bool" style={{ marginBottom: "1rem" }}>
                <MDTypography
                  color="text"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  variant="body2"
                  fontWeight="medium"
                  style={{ width: "100%" }}
                >
                  {branch.name}
                  <Tooltip
                    style={{ marginRight: 2, cursor: "pointer" }}
                    title="Editar sucursal"
                    disableInteractive
                  >
                    <EditIcon onClick={() => handleEdit(branch)}>Editar</EditIcon>
                  </Tooltip>
                </MDTypography>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </BKModal>
  );
}

ModalBranches.defaultProps = {
  organizationGuid: "",
};

ModalBranches.propTypes = {
  modalBranch: PropTypes.bool.isRequired,
  setModalBranch: PropTypes.func.isRequired,
  organizationGuid: PropTypes.string,
  showNotificationError: PropTypes.func.isRequired,
  showNotificationSuccess: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
};

export default ModalBranches;
