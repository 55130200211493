import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Autocomplete } from "@mui/material";
import { isOk } from "views/admin/xpos/_helpers/requestHelpers";
import xPosApiRequest from "../../../../../../api/xPosApiRequest";
import MDTypography from "../../../../../../components/MDTypography";
import MDInput from "../../../../../../components/MDInput";

function Storage({
  setLoading,
  showNotificationError,
  showNotificationSuccess,
  country,
  organizationConfigGuid,
  data,
}) {
  const [tipoGoSoValue, setTipoGoSoValue] = useState("");
  const [endpointGoSoValue, setEndpointGoSoValue] = useState("");
  const [cadenaGoSoValue, setCadenaGoSoValue] = useState("");
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  useEffect(() => {
    if (data) {
      setTipoGoSoValue(data.endPointStorageType);
      setEndpointGoSoValue(data.endPointStorage);
      setCadenaGoSoValue(data.endPointStorage);
    }
  }, [data]);

  const handleStorage = async () => {
    setLoading(true);
    const body = {
      endPointStorage: tipoGoSoValue === "API" ? endpointGoSoValue : cadenaGoSoValue,
      storageUpdatedBy: userInfo.correo,
      endPointStorageType: tipoGoSoValue,
    };

    let options;

    if (organizationConfigGuid) {
      options = {
        method: "PUT",
        url: `/configuration/storage`,
        headers: {
          "Content-Type": "application/json",
          organizationConfigGuid,
        },
        data: body,
      };
    } else {
      options = {
        method: "PUT",
        url: `/global-configuration/storage`,
        headers: {
          "Content-Type": "application/json",
          countryId: country,
        },
        data: body,
      };
    }

    try {
      const response = await xPosApiRequest(options);
      if (isOk(response.status)) {
        showNotificationSuccess("El endpoint storage se actualizó exitosamente");
      } else {
        showNotificationError(response.data);
      }
    } catch (error) {
      showNotificationError(`Ocurrio un error al actualizar el endpoint storage`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container spacing={4} pb={2} px={1}>
      <Grid item xs={6}>
        <MDTypography fontWeight="bold" fontSize="14px" color="dark">
          Aquí se registra el endpoint del Storage Gosocket para el envío de documentos en método
          equivalente a SendDocumentToSave.
        </MDTypography>
        <MDTypography fontSize="14px" color="dark">
          <b>Ejemplo API: </b>
          https://developers-sbx.gosocket.net/api/v1/Document/SendDocumentToSave
        </MDTypography>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Autocomplete
              options={["Storage", "API"]}
              size="medium"
              value={tipoGoSoValue}
              onChange={(event, newValue) => setTipoGoSoValue(newValue)}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  label="Tipo de publicación a Gosocket"
                  placeholder="Seleccione"
                  variant="outlined"
                  fullWidth
                  required
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>

          {tipoGoSoValue === "API" ? (
            <Grid item xs={12}>
              <MDInput
                label="Endpoint"
                placeholder="Ingrese Endpoint"
                type="text"
                value={endpointGoSoValue}
                onChange={(event) => setEndpointGoSoValue(event.target.value)}
                size="medium"
                variant="outlined"
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <MDInput
                label="Cadena de conexión"
                placeholder="Ingrese Cadena"
                type="text"
                value={cadenaGoSoValue}
                onChange={(event) => setCadenaGoSoValue(event.target.value)}
                size="medium"
                variant="outlined"
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          )}

          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Grid
              backgroundColor="#099268"
              width="110px"
              borderRadius={5}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                handleStorage();
              }}
            >
              <MDTypography
                fontWeight="bold"
                color="white"
                py={1}
                style={{ marginLeft: 5, paddingRight: 5, fontSize: 12 }}
              >
                Guardar
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

Storage.defaultProps = {
  showNotificationError: () => {},
  showNotificationSuccess: () => {},
  organizationConfigGuid: "",
  data: {},
};

Storage.propTypes = {
  setLoading: PropTypes.func.isRequired,
  country: PropTypes.string.isRequired,
  showNotificationError: PropTypes.func,
  showNotificationSuccess: PropTypes.func,
  organizationConfigGuid: PropTypes.string,
  data: PropTypes.shape({
    endPointStorage: PropTypes.string,
    endPointStorageType: PropTypes.string,
    storageUpdatedAt: PropTypes.string,
    storageUpdatedBy: PropTypes.string,
  }),
};
export default Storage;
