/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import PropTypes from "prop-types";

// react-router components
import { Link, useLocation } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ArrowBack from "@mui/icons-material/ArrowBack";

// Material Dashboard 2 React example components
import MDInput from "components/MDInput";
import DashboardLayout from "../../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../../../examples/Footer";
import NewDataTable from "../../../../../examples/Tables/NewDataTable";

// Data
import data from "./data";

// Material Dashboard 2 React components
// import MDSnackbar from "../../../../../components/MDSnackbar";
import MDTypography from "../../../../../components/MDTypography";
import MDBox from "../../../../../components/MDBox";

// API
import xPosApiRequest from "../../../../../api/xPosApiRequest";
import { isOk } from "../../_helpers/requestHelpers";
import { CountryCodesToNames } from "../../_constants/general";
import Notification from "../../_components/shared/Notification";
import useNotification from "../../_hooks/useNotification";

function ContingenciasHistory({ country }) {
  const location = useLocation();
  const { cont } = location.state || {};
  const [loadingTable, setLoadingTable] = useState(false);
  const [filteredData, setFilteredData] = useState(undefined);
  const [dateValue, setDateValue] = useState("");

  const { notification, showNotificationError, showNotificationSuccess, setNotification } =
    useNotification();

  const [jsonData, setJsonData] = useState([]);

  const fetchData = async () => {
    const options = {
      method: "GET",
      url: `/contingency/history?xposGuid=${cont.xPosGuid}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    setLoadingTable(true);
    try {
      const response = await xPosApiRequest(options);
      if (isOk(response.status)) {
        const logsData = await response.data;
        setJsonData(logsData);
      } else {
        showNotificationError("Ocurrio un error al cargar las contingencias.");
      }
      setLoadingTable(false);
    } catch (error) {
      showNotificationError(error.message);
      setLoadingTable(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearchDate = (contingencyStartDate) => {
    setDateValue(contingencyStartDate);
    const filteredContingencies = jsonData.filter((contingency) =>
      contingency.contingencyStartDate?.toLowerCase().includes(contingencyStartDate.toLowerCase())
    );
    setFilteredData(filteredContingencies);
  };

  const currentDate = () => {
    const year = new Date().toLocaleString("default", { year: "numeric" });
    const month = new Date().toLocaleString("default", {
      month: "2-digit",
    });
    const day = new Date().toLocaleString("default", { day: "2-digit" });

    return [year, month, day].join("-");
  };

  const handleDownload = async (arrayIds) => {
    const options = {
      method: "POST",
      url: "/contingency/download-id/last",
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "blob",
      data: arrayIds,
    };

    try {
      const response = await xPosApiRequest(options);
      if (isOk(response.status)) {
        const blob = new Blob([response.data], { type: "application/zip" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "contingency.zip");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        showNotificationSuccess(
          `${
            arrayIds.length > 1 ? "Archivos descargados" : "Archivo descargado"
          } satisfactoriamente.`
        );
      } else {
        showNotificationError(`Error al descargar archivo.`);
      }
    } catch (error) {
      showNotificationError(`Error al descargar archivo.`);
    }
  };

  const { columns, rows } = data(filteredData || jsonData, handleDownload);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid display="flex" alignItems="center">
        <Tooltip title="Regresar" style={{ paddingLeft: 0 }} disableInteractive>
          <IconButton
            style={{ paddingLeft: 0 }}
            disableRipple
            component={Link}
            to={`/xPos/9${CountryCodesToNames[country]}/9Contingencia`}
          >
            <ArrowBack style={{ cursor: "pointer", color: "black" }} />
          </IconButton>
        </Tooltip>
      </Grid>
      <MDBox py={2}>
        <MDTypography fontWeight="bold" variant="h6" color="dark" noWrap>
          Nombre xPOS: {cont.xPosName}
        </MDTypography>
        <MDTypography fontWeight="bold" variant="h6" color="dark" noWrap>
          ID xPOS: {cont.xPosGuid}
        </MDTypography>
        <MDBox pt={4} pb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} py={1}>
              <MDBox display="flex" justifyContent="space-between">
                <MDBox width="12rem">
                  <MDInput
                    label="Fecha inicio contingencia"
                    placeholder="B�squeda por fecha"
                    type="date"
                    value={dateValue}
                    onChange={(event) => handleSearchDate(event.target.value)}
                    size="small"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ max: currentDate() }}
                  />
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <MDBox>
                  <NewDataTable
                    entriesPerPage={(false, { defaultValue: 10 })}
                    canSearch
                    checkbox
                    tableType="contingencias"
                    downloadFile={handleDownload}
                    showTotalEntries
                    loadingTable={loadingTable}
                    table={{ columns, rows }}
                    pagination={{ variant: "contained", color: "warning" }}
                    isSorted
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Notification notification={notification} setNotification={setNotification} />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

ContingenciasHistory.propTypes = {
  country: PropTypes.string.isRequired,
};

export default ContingenciasHistory;
