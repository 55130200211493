/* eslint-disable no-undef */
/* eslint-disable no-loop-func */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
// import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";

export default function data(jsonData, updateUploadDocPermJsonData) {
  const getRows = () => {
    const elements = [];

    for (let index = 0; index < jsonData.length; index += 1) {
      elements.push({
        pais: jsonData[index].pais ? jsonData[index].pais.nombre : "...",
        taxId: jsonData[index].taxId,
        UploadDocuPermission: (
          <Tooltip
            title={jsonData[index].UploadDocuPermission ? "Desactivar" : "Activar"}
            disableInteractive
          >
            <Switch
              checked={jsonData[index].UploadDocuPermission}
              onChange={() => updateUploadDocPermJsonData(jsonData[index].taxId)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Tooltip>
        ),
        usuario: jsonData[index].usuario,
        fechaMod: jsonData[index].fechaMod,
      });
    }

    return elements;
  };

  return {
    columns: [
      { Header: "Pais", accessor: "pais", align: "center" },
      { Header: "Tax Id", accessor: "taxId", align: "center" },
      { Header: "Carga de documentos", accessor: "UploadDocuPermission", align: "center" },
      { Header: "Ultimo usuario modifico", accessor: "usuario", align: "center" },
      { Header: "Fecha ultima modificacion", accessor: "fechaMod", align: "center" },
    ],

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
  updateUploadDocPermJsonData: PropTypes.func.isRequired,
};
