import React from "react";
import PropTypes from "prop-types";
import ItemCustomConfiguration from "./ItemCustomConfiguration";

function CustomConfiguration({
  showNotificationError,
  showNotificationSuccess,
  setLoading,
  data,
  organizationConfigGuid,
  countryId,
}) {
  return (
    <>
      {data.map((item) => (
        <ItemCustomConfiguration
          showNotificationError={showNotificationError}
          showNotificationSuccess={showNotificationSuccess}
          setLoading={setLoading}
          data={item}
          organizationConfigGuid={organizationConfigGuid}
          key={item.id}
          country={countryId}
        />
      ))}
    </>
  );
}

CustomConfiguration.defaultProps = {
  organizationConfigGuid: "",
  countryId: "",
};

CustomConfiguration.propTypes = {
  showNotificationError: PropTypes.func.isRequired,
  showNotificationSuccess: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.number,
      forms: PropTypes.array,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      actiondescription: PropTypes.string,
    })
  ).isRequired,
  organizationConfigGuid: PropTypes.string,
  countryId: PropTypes.string,
};
export default CustomConfiguration;
