/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Loading from "components/Loading";
import MDDatePicker from "components/MDDatePicker";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
// import BKModal from "views/varios/Manager/CrearEmpresa/modalContactos";
import dataColumns from "views/manager/usuarios/data";

// API Request
import APIRequest from "api/request";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {/* <MDTypography>{children}</MDTypography> */}
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ManagerUser() {
  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [load, setLoad] = useState(false);
  // const [modalUpload, setModalUpload] = useState(false);
  const [jsonData, setJsonData] = useState({});
  const [jsonDataAccounts, setJsonDataAccounts] = useState([]);
  const { pathname } = useLocation();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [searchEmail, setSearchEmail] = useState("");

  const [editForm, setEditForm] = useState(false);
  const [newValuePais, setNewValuePais] = useState(null);
  const [newValueEmail, setNewValueEmail] = useState("");
  const [newValueNombre, setNewValueNombre] = useState("");
  const [newValueRut, setNewValueRut] = useState("");
  const [newValueGenero, setNewValueGenero] = useState(null);
  const [newValueFechaNacimiento, setNewValueFechaNacimiento] = useState("");
  const [newValueFechaRegistro, setNewValueFechaRegistro] = useState("");
  const [newValueStatus, setNewValueStatus] = useState(null);

  const [activeDatosPassword, setActiveDatosPassword] = useState(false);
  const [newValueValorClave, setNewValueValorClave] = useState("");

  // const [newValueXSLT, setNewValueXSLT] = useState("");
  // const [newValueFiles, setNewValueFiles] = useState([]);
  // const [newValueFileBase64, setNewValueFileBase64] = useState("");

  const [selectData, setSelectData] = useState({
    pais: [
      { id: "cr", nombre: "Costa rica" },
      { id: "ec", nombre: "Ecuador" },
      { id: "pe", nombre: "Perú" },
      { id: "cl", nombre: "Chile" },
      { id: "mx", nombre: "México" },
      { id: "gt", nombre: "Guatemala" },
      { id: "co", nombre: "Colombia" },
      { id: "pa", nombre: "Panamá" },
    ],
    genero: [
      { id: 1, nombre: "Masculino" },
      { id: 2, nombre: "Femenina" },
    ],
    status: [
      { id: 0, nombre: "Usuario no activo" },
      { id: 1, nombre: "Usuario activo" },
      { id: 2, nombre: "Usuario bloqueado" },
    ],
  });
  const [tabValue, setTabValue] = useState(0);

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };
  /* const closeModalUpload = () => {
    setModalUpload(false);
    setNewValueXSLT("");
    setNewValueFiles([]);
    setNewValueFileBase64("");
    setLoad(false);
  }; */
  const clear = () => {
    setJsonData({});
    setJsonDataAccounts([]);
    setSearchEmail("");
    setEditForm(false);
    setNewValuePais(null);
    setNewValueEmail("");
    setNewValueNombre("");
    setNewValueRut("");
    setNewValueGenero(null);
    setNewValueFechaNacimiento("");
    setNewValueFechaRegistro("");
    setNewValueStatus(null);
    setActiveDatosPassword(false);
    setNewValueValorClave("");
    // setNewValueXSLT("");
    // setNewValueFiles([]);
    // setNewValueFileBase64("");
  };

  /* const lector = new FileReader();
  lector.onload = async (file) => {
    if (file) {
      setNewValueFileBase64(
        file.target.result
          .toString()
          .replace("data:application/xml;base64,", "")
          .replace("data:text/xml;base64,", "")
          .replace("data:application/x-zip-compressed;base64,", "")
          .replace("data:application/octet-stream;base64,", "")
      );
    } else {
      console.log("No se leyó ningun archivo");
    }
  }; */

  const getJsonData = async () => {
    setLoad(true);
    try {
      const body = {
        email: searchEmail,
      };
      const apiResponse = await APIRequest(
        "POST",
        `/api/Manager/User/Todos?email=${searchEmail}`,
        body
      );

      if (apiResponse.status === 200 || apiResponse.status === 201 || apiResponse.ok) {
        const jsonResponse = await apiResponse.json();

        if (jsonResponse.length > 0) {
          const index = 0;
          const tempElement = {
            userId: jsonResponse[index].userId,
            email: searchEmail,
            name: jsonResponse[index].name,
            code: jsonResponse[index].code,
            gender:
              selectData.genero[
                selectData.genero.map((item) => item.id).indexOf(jsonResponse[index].genderId)
              ], // 1
            birth: jsonResponse[index].birth,
            timestamp: jsonResponse[index].timestamp,
            status:
              selectData.status[
                selectData.status.map((item) => item.id).indexOf(jsonResponse[index].statusId)
              ], // 1
            currentCountry:
              selectData.pais[
                selectData.pais.map((item) => item.id).indexOf(jsonResponse[index].currentCountryId)
              ],
          };

          tempElement.gender = tempElement.gender ? tempElement.gender : null;
          tempElement.status = tempElement.status ? tempElement.status : null;
          tempElement.currentCountry = tempElement.currentCountry
            ? tempElement.currentCountry
            : null;

          setNewValueEmail(tempElement.email);
          setNewValueNombre(jsonResponse[index].name ? jsonResponse[index].name : "");
          setNewValueRut(jsonResponse[index].code ? jsonResponse[index].code : "");
          setNewValueFechaNacimiento(jsonResponse[index].birth ? jsonResponse[index].birth : "");
          setNewValueFechaRegistro(
            jsonResponse[index].timestamp ? jsonResponse[index].timestamp : ""
          );
          setNewValuePais(tempElement.currentCountry);
          setNewValueStatus(tempElement.status);
          setNewValueGenero(tempElement.gender);

          setJsonData(tempElement);
          showNotificationSuccess("Se encontró registro");
        } else {
          showNotificationSuccess("No se encontró el registro");
        }
      } else {
        showNotificationError(`Error, estado: ${apiResponse.status}`);
      }
    } catch {
      showNotificationError("Error Inesperado");
    }

    setLoad(false);
  };

  const getJsonDataAccounts = async () => {
    setLoad(true);
    try {
      const body = {
        email: searchEmail,
        userId: jsonData.userId,
      };
      const apiResponse = await APIRequest(
        "POST",
        `/api/Manager/User/Cuentas/${
          jsonData.currentCountry != null ? jsonData.currentCountry.id : "cl"
        }`,
        body
      );

      if (apiResponse.status === 200 || apiResponse.status === 201 || apiResponse.ok) {
        const jsonResponse = await apiResponse.json();
        setJsonDataAccounts([...jsonResponse]);
        showNotificationSuccess("Se encontraron los registros");
      } else {
        showNotificationError(`Error, estado: ${apiResponse.status}`);
      }
    } catch {
      showNotificationError("Error Inesperado");
    }

    setLoad(false);
  };

  /* const saveDataModalUpload = async () => {
    if (newValueFiles.length > 0) {
      setLoad(true);
      try {
        const body = {
          Base64: newValueFileBase64,
        };
        const apiResponse = await APIRequest("POST", "/api/Manager/User/Masivo", body);

        if (apiResponse.status === 200 || apiResponse.status === 201 || apiResponse.ok) {
          showNotificationSuccess("Se crearon los usuarios exitosamente");
          closeModalUpload();
        } else {
          showNotificationError(`Error: ${apiResponse.status}`);
        }
      } catch {
        showNotificationError("Ocurrió un error inesperado.");
      }
      setLoad(false);
    } else {
      showNotificationError("Primero debe cargar el archivo csv.");
    }
  }; */

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );
  /* const renderModalUpload = (
    <BKModal
      closeModal={closeModalUpload}
      saveChanges={saveDataModalUpload}
      open={modalUpload}
      title="Crear Usuarios Masivamente"
    >
      <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
        <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <MDInput
              id="file-input"
              variant="standard"
              type="file"
              label="Seleccionar Archivo"
              sx={{ display: "none" }}
              fullWidth
              InputLabelProps={{ shrink: true }}
              inputProps={{ accept: ".xslt" }}
              onChange={async (e) => {
                setNewValueFiles(e.target.files);
                const tempValueName = e.target.files[0] ? e.target.files[0].name : "";
                setNewValueXSLT(tempValueName.length > 0 ? tempValueName : newValueXSLT);
                // await lector.readAsText(e.target.files[0]);
                await lector.readAsDataURL(e.target.files[0]);
              }}
            />
            <MDButton
              variant="outlined"
              autoFocus
              color="warning"
              onClick={() => document.getElementById("labelFile").click()}
            >
              <label id="labelFile" htmlFor="file-input" style={{ display: "none" }} />
              Adjuntar Archivo <Icon>upload</Icon>
            </MDButton>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <MDInput
              variant="standard"
              type="text"
              label="Usuarios"
              fullWidth
              required
              disabled
              InputLabelProps={{ shrink: true }}
              value={newValueXSLT}
              onChange={(e) => {
                setNewValueXSLT(e.target.value);
              }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </BKModal>
  ); */

  const setEditJsonDataUsuario = async () => {
    setLoad(true);
    if (jsonData.userId) {
      setEditForm(!editForm);
    }
    setLoad(false);
  };
  const saveJsonDataUsuario = async () => {
    setLoad(true);
    try {
      if (newValueFechaNacimiento === "") {
        showNotificationError("Ingrese la fecha de nacimiento.");
      } else if (editForm && jsonData.userId) {
        const HttpUrl = `/api/Manager/User/${jsonData.userId}`;
        const body = {
          email: newValueEmail.trim(),
          code: newValueRut.trim(),
          name: newValueNombre.trim(),
          birth: newValueFechaNacimiento,
          gender: newValueGenero.id,
          currentCountryId: newValuePais.id,
        };

        const apiResponse = await APIRequest("PATCH", HttpUrl, body);

        if (apiResponse.status === 200 || apiResponse.status === 201 || apiResponse.ok) {
          const tempElement = jsonData;
          tempElement.email = body.email;
          tempElement.code = body.code;
          tempElement.name = body.name;
          tempElement.birth = body.birth;
          tempElement.gender = newValueGenero;
          tempElement.currentCountry = newValuePais;
          setJsonData(tempElement);
          showNotificationSuccess("Se actualizó el usuario exitosamente");
        } else {
          showNotificationError(`Error, estado: ${apiResponse.status}`);
        }

        setEditForm(false);
      }
    } catch {
      showNotificationError("Error Inesperado");
    }

    setLoad(false);
  };
  const setEditJsonDataPassword = async () => {
    setLoad(true);
    try {
      if (jsonData.userId) {
        if (
          activeDatosPassword &&
          newValueValorClave !== null &&
          newValueValorClave !== undefined &&
          newValueValorClave !== ""
        ) {
          const apiResponse = await APIRequest(
            "PATCH",
            `/api/Manager/User/CambioContraseña/${jsonData.userId}?password=${newValueValorClave}`,
            null
          );

          if (apiResponse.status === 200 || apiResponse.status === 201 || apiResponse.ok) {
            setNewValueValorClave("");
            showNotificationSuccess("Se actualizó la clave exitosamente");
          } else {
            showNotificationError(`Error, estado: ${apiResponse.status}`);
          }
        }

        setActiveDatosPassword(!activeDatosPassword);
      }
    } catch {
      showNotificationError("Error Inesperado");
    }

    setLoad(false);
  };

  const setUnlockUser = async () => {
    setLoad(true);
    try {
      if (jsonData.userId) {
        const apiResponse = await APIRequest(
          "POST",
          `/api/Manager/User/DesbloquearUsuario/${searchEmail}`,
          null
        );

        if (apiResponse.status === 200 || apiResponse.status === 201 || apiResponse.ok) {
          showNotificationSuccess("Se desbloqueo el usuario exitosamente");
        } else {
          showNotificationError(`Error, estado: ${apiResponse.status}`);
        }
      }
    } catch {
      showNotificationError("Error Inesperado");
    }

    setLoad(false);
  };

  const setCancelJsonDataUsuario = async () => {
    setEditForm(false);
  };

  const getCountriesData = async () => {
    const tempJsonData = [];
    const apiResponse = await (await APIRequest("GET", "/api/Pais/Todos", null)).json();

    if (apiResponse.length > 0) {
      for (let index = 0; index < apiResponse.length; index += 1) {
        tempJsonData.push({
          id: apiResponse[index].codigo.toLowerCase(),
          nombre: apiResponse[index].nombre,
        });
      }

      setSelectData({
        pais: tempJsonData,
        genero: selectData.genero,
        status: selectData.status,
      });
    }

    return tempJsonData;
  };
  const getElementChanges = async () => {
    if (state) {
      if (state.newId && state.newEmail) {
        showNotificationSuccess(
          `Se creó el usuario ${state.newEmail} exitosamente con id: ${state.newId}`
        );
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    getJsonData();
  };

  const handleSubmitUser = (event) => {
    event.preventDefault();
    saveJsonDataUsuario();
  };

  const handleTabChange = (event, newValue) => {
    if (jsonData.userId != null) {
      setTabValue(newValue);

      switch (newValue) {
        case 0: // Datos Usuario
          break;
        case 1: // Empresas Vinculadas Pais
          getJsonDataAccounts();
          break;
        case 2: // Cambiar Contraseña
          break;
        default:
          break;
      }
    }
  };

  useEffect(async () => {
    await getCountriesData();
    await getElementChanges();
  }, [pathname]);

  const { columns, rows } = dataColumns(jsonDataAccounts);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <MDTypography variant="subtitle" fontWeight="bold">
              Manager - Usuarios
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox
                sx={{ position: "relative", mx: 3, py: 2, px: 2 }}
                component="form"
                onSubmit={handleSubmit}
              >
                <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Email usuario"
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      value={searchEmail}
                      onChange={(e) => {
                        setSearchEmail(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={8} xl={9} />
                  <Grid item xs={12} sm={12} md={4} lg={5} xl={2}>
                    <MDButton
                      variant="contained"
                      type="submit"
                      sx={{
                        background: "linear-gradient(45deg, #1971C2 30%, #1971C2 90%)",
                        color: "#FFFFFF",
                      }}
                    >
                      <Icon>search</Icon>&nbsp;Buscar
                    </MDButton>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={5} xl={2}>
                    <MDButton
                      variant="contained"
                      onClick={() => clear()}
                      sx={{
                        background: "linear-gradient(45deg, #A0A0A0 30%, #A0A0A0 90%)",
                        color: "#FFFFFF",
                      }}
                    >
                      <Icon>cancel</Icon>&nbsp;Limpiar
                    </MDButton>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={5} xl={2}>
                    <MDButton
                      variant="contained"
                      onClick={() => {
                        clear();
                        // setNewForm(true);
                        navigate("/Manager/User/Form", { state: { route: pathname } });
                      }}
                      sx={{
                        background: "linear-gradient(45deg, #099268 30%, #099268 90%)",
                        color: "#FFFFFF",
                      }}
                    >
                      <Icon>cancel</Icon>&nbsp;Nuevo usuario
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="Datos Usuario" {...a11yProps(0)} />
                    <Tab label="Empresas Vinculadas Pais" {...a11yProps(1)} />
                    <Tab label="Cambiar Contraseña" {...a11yProps(2)} />
                    <Tab label="Desbloquear usuario" {...a11yProps(3)} />
                  </Tabs>
                </Box>
                <TabPanel value={tabValue} index={0}>
                  <Card>
                    <MDBox
                      sx={{ position: "relative", mx: 3, py: 2, px: 2 }}
                      component="form"
                      onSubmit={handleSubmitUser}
                    >
                      <Grid
                        container
                        spacing={3}
                        justifyContent="left"
                        alignItems="left"
                        height="100%"
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          container
                          justifyContent="flex-end"
                        >
                          <MDButton
                            variant="contained"
                            onClick={() => setEditJsonDataUsuario()}
                            sx={{
                              background: "linear-gradient(45deg, #FFC300 30%, #FFC300 90%)",
                              color: "#FFFFFF",
                            }}
                          >
                            <Icon>edit</Icon>&nbsp;Editar Usuario
                          </MDButton>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                          <MDInput
                            variant="standard"
                            type="text"
                            label="Email"
                            fullWidth
                            required
                            disabled={!editForm}
                            InputLabelProps={{ shrink: true }}
                            value={newValueEmail}
                            onChange={(e) => {
                              setNewValueEmail(e.target.value);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                          <MDInput
                            variant="standard"
                            type="text"
                            label="Nombre"
                            fullWidth
                            required
                            disabled={!editForm}
                            InputLabelProps={{ shrink: true }}
                            value={newValueNombre}
                            onChange={(e) => {
                              setNewValueNombre(e.target.value);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                          <Autocomplete
                            value={newValuePais}
                            onChange={(event, newValue) => {
                              setNewValuePais(newValue);
                            }}
                            options={selectData.pais}
                            getOptionLabel={(option) => option.nombre}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            disabled={!editForm}
                            renderInput={(params) => (
                              <MDInput
                                variant="standard"
                                type="text"
                                label="Pais Actual"
                                fullWidth
                                required
                                InputLabelProps={{ shrink: true }}
                                {...params}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                          <MDInput
                            variant="standard"
                            type="text"
                            label="Rut Usuario"
                            fullWidth
                            // required
                            disabled={!editForm}
                            InputLabelProps={{ shrink: true }}
                            value={newValueRut}
                            onChange={(e) => {
                              setNewValueRut(e.target.value);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                          <Autocomplete
                            value={newValueGenero}
                            onChange={(event, newValue) => {
                              setNewValueGenero(newValue);
                            }}
                            options={selectData.genero}
                            getOptionLabel={(option) => option.nombre}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            disabled={!editForm}
                            renderInput={(params) => (
                              <MDInput
                                variant="standard"
                                type="text"
                                label="Género"
                                fullWidth
                                required
                                InputLabelProps={{ shrink: true }}
                                {...params}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                          <MDDatePicker
                            options={{
                              enableTime: false,
                              dateFormat: "d-m-Y",
                            }}
                            input={{
                              fullWidth: true,
                              variant: "standard",
                              label: "Fecha de Nacimiento",
                              required: true,
                              disabled: !editForm,
                              InputLabelProps: { shrink: true },
                            }}
                            value={newValueFechaNacimiento}
                            onChange={(e) => {
                              setNewValueFechaNacimiento(e[0]);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                          <MDDatePicker
                            options={{
                              enableTime: false,
                              dateFormat: "d-m-Y",
                            }}
                            input={{
                              fullWidth: true,
                              variant: "standard",
                              label: "Fecha de Registro",
                              disabled: true,
                              InputLabelProps: { shrink: true },
                            }}
                            disabled
                            InputLabelProps={{ shrink: true }}
                            value={newValueFechaRegistro}
                            onChange={(e) => {
                              setNewValueFechaRegistro(e[0]);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                          <Autocomplete
                            value={newValueStatus}
                            onChange={(event, newValue) => {
                              setNewValueStatus(newValue);
                            }}
                            options={selectData.status}
                            getOptionLabel={(option) => option.nombre}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            disabled={!editForm}
                            renderInput={(params) => (
                              <MDInput
                                variant="standard"
                                type="text"
                                label="Estado"
                                fullWidth
                                required
                                InputLabelProps={{ shrink: true }}
                                {...params}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          container
                          justifyContent="flex-end"
                          alignItems="center"
                          sx={{ "& > button": { m: 1 } }}
                        >
                          <MDButton
                            variant="contained"
                            type="submit"
                            sx={{
                              display: editForm ? "block" : "none",
                            }}
                            color="success"
                          >
                            <Icon>save</Icon>&nbsp;Guardar
                          </MDButton>
                          <MDButton
                            variant="contained"
                            type="submit"
                            onClick={() => setCancelJsonDataUsuario()}
                            sx={{
                              display: editForm ? "block" : "none",
                            }}
                            color="error"
                          >
                            <Icon>cancel</Icon>&nbsp;Cancelar
                          </MDButton>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </Card>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <Card>
                    <MDBox>
                      <DataTable
                        entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                        canSearch
                        showTotalEntries
                        table={{ columns, rows }}
                        pagination={{ variant: "contained", color: "warning" }}
                        isSorted
                        noEndBorder
                      />
                    </MDBox>
                  </Card>
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                  <Card>
                    <MDBox
                      sx={{ position: "relative", mx: 3, py: 2, px: 2 }}
                      // component="form"
                      // onSubmit={handleSubmit}
                    >
                      <Grid
                        container
                        spacing={3}
                        justifyContent="left"
                        alignItems="left"
                        height="100%"
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          container
                          justifyContent="flex-end"
                        >
                          <MDButton
                            variant="contained"
                            onClick={() => setEditJsonDataPassword()}
                            sx={{
                              background: "linear-gradient(45deg, #FFC300 30%, #FFC300 90%)",
                              color: "#FFFFFF",
                            }}
                          >
                            <Icon>edit</Icon>&nbsp;Editar Contraseña
                          </MDButton>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                          <MDInput
                            variant="standard"
                            type="text"
                            label="Valor de la Clave"
                            fullWidth
                            disabled={!activeDatosPassword}
                            InputLabelProps={{ shrink: true }}
                            value={newValueValorClave}
                            onChange={(e) => {
                              setNewValueValorClave(e.target.value);
                            }}
                            multiline
                            rows={4}
                          />
                        </Grid>
                      </Grid>
                    </MDBox>
                  </Card>
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                  <Card>
                    <MDBox
                      sx={{ position: "relative", mx: 3, py: 2, px: 2 }}
                      // component="form"
                      // onSubmit={handleSubmit}
                    >
                      <Grid
                        container
                        spacing={3}
                        justifyContent="left"
                        alignItems="left"
                        height="100%"
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          container
                          justifyContent="center"
                        >
                          <MDButton
                            variant="contained"
                            onClick={() => setUnlockUser()}
                            sx={{
                              background: "linear-gradient(45deg, #FFC300 30%, #FFC300 90%)",
                              color: "#FFFFFF",
                            }}
                          >
                            <LockOpenIcon />
                            &nbsp;Desbloquear
                          </MDButton>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </Card>
                </TabPanel>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      {/* renderModalUpload */}
      <Loading load={load} />
      <Footer />
    </DashboardLayout>
  );
}

export default ManagerUser;
