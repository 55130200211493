import React, { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Alert from "@mui/material/Alert";
import { Snackbar, Stack } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// API Request
import APIRequest from "api/request";

function Calculadora() {
  // Estados para los valores de entrada
  const [numArchivos, setNumArchivos] = useState(600000000);
  const [pesoArchivo, setPesoArchivo] = useState(13);
  const [mesesDisponibilidad, setMesesDisponibilidad] = useState(1);
  const [ru, setRU] = useState(100);
  const [tstorage, setTstorage] = useState(0);
  const [tw, setTw] = useState(0);
  const [tread, setTread] = useState(0);
  const [tindex, setTindex] = useState(0);
  const [tallOper, setTallOper] = useState(0);
  const [tbw, setTbw] = useState(0);
  const [pesoTotal, setPesoTotal] = useState(0);
  const [tserverSFTP, setTserverSFTP] = useState(0);
  const [tstorageXMes, setTstorageXMes] = useState(0);
  const [toperacion, setToperacion] = useState(0);
  const [tbasedatos, setTbasedatos] = useState(0);
  const [tvalorTotal, setTvalorTotal] = useState(0);
  const [open, setOpen] = useState(false);
  const [alertType, setAlertType] = useState("error"); // "success" o "error"
  const [alertMessage, setAlertMessage] = useState(""); // Mensaje personalizado
  const [jdata, setJdata] = useState({});

  // variable tabla costos
  const datos2 = "Total";

  const cargarData = async () => {
    try {
      const apiResponse = await APIRequest("Get", "/Api/Calculadora/Todos", null);
      if (apiResponse.status === 200) {
        const dataResponse = await apiResponse.json();
        setJdata(dataResponse[0]);
      }
    } catch (e) {
      setAlertType("error");
      setAlertMessage(`Ocurrió un error al consumir la api: ${e.message}`);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") return; // Evitar cerrar por clic fuera
    setOpen(false);
  };

  const saveDataModal = async () => {
    // validaciones
    let mensaje = "";
    if (numArchivos === "" || Number(numArchivos) === 0) {
      mensaje = "Debe ingresar un valor diferente a 0 en el campo no archivos \n ";
    }
    if (pesoArchivo === "" || Number(pesoArchivo) === 0) {
      mensaje += "Debe ingresar un valor en el campo Peso del Archivo en KB. \n ";
    }
    if (mesesDisponibilidad === "" || Number(mesesDisponibilidad) === 0) {
      mensaje += "Debe ingresar un valor en el meses disponible. \n ";
    }
    if (ru === "" || Number(ru) === 0) {
      mensaje += "Debe ingresar un valor en el campo RU.";
    }
    if (mensaje !== "") {
      setAlertType("error");
      setAlertMessage(mensaje);
      setOpen(true);
    } else {
      setOpen(false);
      // variable en cero
      setPesoTotal(0);
      setTstorage(0);
      setTw(0);
      setTread(0);
      setTindex(0);
      setTallOper(0);
      setTbw(0);
      setToperacion(0);
      setTbasedatos(0);
      setTserverSFTP(0);
      setTstorageXMes(0);
      setTvalorTotal(0);
      setPesoTotal(numArchivos * pesoArchivo); // Peso total
      const valorKB = (numArchivos * pesoArchivo) / (1024 * 1024);
      setTstorage(jdata.store * valorKB); // storage
      const valorW = Number(numArchivos) / 10000;
      setTw(jdata.w * valorW); // w
      const calRead = numArchivos / 10000;
      setTread(jdata.read * calRead * 2); // read
      setTindex(jdata.index * calRead); // index
      setTallOper(jdata.allOperation * calRead); // all operacion
      setTbw(jdata.bw * valorKB * 2); // BW
      const sumaOp =
        jdata.store * valorKB +
        jdata.w * valorW +
        jdata.read * calRead * 2 +
        jdata.index * calRead +
        jdata.allOperation * calRead +
        jdata.bw * valorKB * 2;
      setToperacion(sumaOp);
      const calRu = Number(jdata.rufijo) * ru;
      const calRu2 = 60 * 30;
      const calFinalRu = calRu / calRu2;
      const valorbasedatos = (calFinalRu * numArchivos) / 10000;
      setTbasedatos(valorbasedatos);
      const valorsftp = jdata.serverSFTP * mesesDisponibilidad;
      setTserverSFTP(valorsftp);
      const valorstorxmes = Number(mesesDisponibilidad) * (jdata.store * valorKB);
      setTstorageXMes(valorstorxmes);
      // eslint-disable-next-line no-restricted-globals
      const sumaOpNum = isNaN(parseFloat(sumaOp)) ? 0 : parseFloat(sumaOp.toFixed(2));
      // eslint-disable-next-line no-restricted-globals
      const valorbasedatosNum = isNaN(parseFloat(valorbasedatos))
        ? 0
        : parseFloat(valorbasedatos.toFixed(2));
      // eslint-disable-next-line no-restricted-globals
      const valorsftpNum = isNaN(parseFloat(valorsftp)) ? 0 : parseFloat(valorsftp.toFixed(2));
      // eslint-disable-next-line no-restricted-globals
      const valorstorxmesNum = isNaN(parseFloat(valorstorxmes))
        ? 0
        : parseFloat(valorstorxmes.toFixed(2));

      const sumafinal = sumaOpNum + valorbasedatosNum + valorsftpNum + valorstorxmesNum;
      setTvalorTotal(sumafinal); // sacar
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    saveDataModal();
  };
  useEffect(async () => {
    cargarData();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />

      {/* Contenido Principal */}
      <MDBox pt={3} pb={3} component="form" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          {/* Entrada de Datos */}
          <Grid item xs={12} md={6}>
            <MDBox pb={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MDBox pb={4}>
                    <MDTypography variant="subtitle" fontWeight="bold">
                      Calculadora de Costos
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
                      <Grid
                        container
                        spacing={3}
                        justifyContent="left"
                        alignItems="left"
                        height="100%"
                      >
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                          <MDInput
                            variant="standard"
                            type="number"
                            label="No Archivos"
                            id="numArchivos"
                            fullWidth
                            required
                            InputLabelProps={{ shrink: true }}
                            value={numArchivos}
                            onChange={(e) => setNumArchivos(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                          <MDInput
                            variant="standard"
                            type="number"
                            id="pesoArchivo"
                            label="Peso del Archivo en KB:"
                            fullWidth
                            required
                            InputLabelProps={{ shrink: true }}
                            value={pesoArchivo}
                            onChange={(e) => setPesoArchivo(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                          <MDInput
                            variant="standard"
                            type="number"
                            label="Meses de Disponibilidad:"
                            id="mesesDisponibilidad"
                            fullWidth
                            required
                            InputLabelProps={{ shrink: true }}
                            value={mesesDisponibilidad}
                            onChange={(e) => setMesesDisponibilidad(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                          <MDInput
                            variant="standard"
                            type="number"
                            label="RU:"
                            id="ru"
                            fullWidth
                            required
                            InputLabelProps={{ shrink: true }}
                            value={ru}
                            onChange={(e) => setRU(e.target.value)}
                          />
                        </Grid>
                      </Grid>
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <MDBox p={3} style={{ border: "1px solid #ccc", borderRadius: "8px" }}>
                <div>
                  <h2 style={{ textAlign: "center", margin: "16px 0" }}>Tabla de Costos</h2>
                  <div style={{ overflowX: "auto" }}>
                    <table style={{ border: "1px solid #ccc" }}>
                      <thead>
                        <tr style={{ border: "1px solid #ccc" }}>
                          {["#", "Storage", "W", "Read", "Index", "All Operación", "BW"].map(
                            (header) => (
                              <th key={header} align="center" style={{ border: "1px solid #ccc" }}>
                                {header}
                              </th>
                            )
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {/* Fila 1 */}
                        <tr>
                          <th style={{ border: "1px solid #ccc" }}>
                            Valor paquete GB
                            <br />
                            10 mil operaciones
                          </th>
                          <th align="center" style={{ border: "1px solid #ccc" }}>
                            {jdata.store}
                          </th>
                          <th align="center" style={{ border: "1px solid #ccc" }}>
                            {jdata.w}
                          </th>
                          <th align="center" style={{ border: "1px solid #ccc" }}>
                            {jdata.read}
                          </th>
                          <th align="center" style={{ border: "1px solid #ccc" }}>
                            {jdata.index}
                          </th>
                          <th align="center" style={{ border: "1px solid #ccc" }}>
                            {jdata.allOperation}
                          </th>
                          <th align="center" style={{ border: "1px solid #ccc" }}>
                            {jdata.bw}
                          </th>
                        </tr>
                        {/* Fila 2 */}
                        <tr>
                          <th style={{ border: "1px solid #ccc" }}>{datos2}</th>
                          <th align="center" style={{ border: "1px solid #ccc" }}>
                            {tstorage}
                          </th>
                          <th align="center" style={{ border: "1px solid #ccc" }}>
                            {tw}
                          </th>
                          <th align="center" style={{ border: "1px solid #ccc" }}>
                            {tread}
                          </th>
                          <th align="center" style={{ border: "1px solid #ccc" }}>
                            {tindex}
                          </th>
                          <th align="center" style={{ border: "1px solid #ccc" }}>
                            {tallOper}
                          </th>
                          <th align="center" style={{ border: "1px solid #ccc" }}>
                            {tbw}
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </MDBox>
            </Grid>
            {/* Resultados */}
            <Grid item xs={12} md={6}>
              <MDBox p={3} style={{ border: "1px solid #ccc", borderRadius: "8px" }}>
                <h2>Resultados:</h2>
                <p>Peso Total: {pesoTotal.toFixed(2)}</p>
                <p>Procesamiento Total: ${toperacion.toFixed(2)}</p>
                <p>Base de datos: ${tbasedatos.toFixed(2)}</p>
                <p>Server SFTP: ${tserverSFTP.toFixed(2)}</p>
                <p>Storage por Mes: ${tstorageXMes.toFixed(2)}</p>
                <h3>Valor Total: ${tvalorTotal.toFixed(2)}</h3>
              </MDBox>
            </Grid>
          </Grid>
          <Stack spacing={2} alignItems="center" mt={4}>
            <Snackbar
              open={open}
              autoHideDuration={4000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert
                onClose={handleClose}
                severity={alertType} // Tipo de alerta dinámico
                sx={{ width: "100%" }}
              >
                {alertMessage}
              </Alert>
            </Snackbar>
          </Stack>
          <Grid item xs={12}>
            <Grid container justifyContent="left" alignItems="left" height="100%">
              <Grid item xs={12} sm={12} md={6} lg={6} xl={8} />
              <Grid item xs={12} sm={12} md={6} lg={3} xl={2} container justifyContent="flex-end">
                <MDButton
                  variant="contained"
                  type="submit"
                  sx={{
                    background: "linear-gradient(45deg, #099268 30%, #099268 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>save</Icon>&nbsp;Calcular
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Calculadora;
