import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// react-router components
import { Link, useLocation } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ArrowBack from "@mui/icons-material/ArrowBack";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Text from "@mui/material/InputLabel";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 React components
import MDInput from "../../../../../components/MDInput";
import MDBox from "../../../../../components/MDBox";
import MDButton from "../../../../../components/MDButton";
import MDTypography from "../../../../../components/MDTypography";
import Loading from "../../../../../components/Loading";

// Material Dashboard 2 React example components
import Footer from "../../../../../examples/Footer";
import DashboardNavbar from "../../../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../../../examples/LayoutContainers/DashboardLayout";

// API
import xPosApiRequest from "../../../../../api/xPosApiRequest";
import useNotification from "../../_hooks/useNotification";
import Notification from "../../_components/shared/Notification";
import { CountryCodesToNames } from "../../_constants/general";
import { isOk } from "../../_helpers/requestHelpers";
import SearchOrganization from "../../_components/shared/SearchOrganization";

function CrearXPOS({ country }) {
  const location = useLocation();
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const { pool } = location.state || {};
  const [taxIDValue, setTaxIDValue] = useState(pool ? pool.taxId : "");
  const [nombreValue, setNombreValue] = useState(pool ? pool.name : "");
  const [tipoDTEValue, setTipoDTEValue] = useState("");
  const [umbralValue, setUmbralValue] = useState("");
  const [numberValue, setNumberValue] = useState("");
  const [selectedIndex, setSelectedIndex] = useState("");
  const [arrayFolios, setArrayFolios] = useState(pool ? pool.folios : []);
  const [loading, setLoading] = useState(false);
  const { notification, showNotificationSuccess, showNotificationError, setNotification } =
    useNotification();
  const [GsValue, setGSValue] = useState(pool?.gs || "");
  const [organizationValue, setOrganizationValue] = useState(pool?.organization ?? {});
  const [branches, setBranches] = useState([]);
  const [branchSelect, setBranchSelect] = useState(
    pool?.branch ? { label: pool?.branch?.name, value: pool?.branch?.guid } : ""
  );

  const [arrayTipoDTE, setArrayTipoDTE] = useState([]);
  useEffect(() => {
    const getDataCountry = async () => {
      try {
        const { default: response } = await import(`./arrayTipoDTE_${country.toUpperCase()}`);
        setArrayTipoDTE(response);
      } catch (error) {
        setArrayTipoDTE([]);
      }
    };

    getDataCountry();
  }, [country]);

  const fetchBranch = async (organizationGuid) => {
    setLoading(true);
    const options = {
      method: "GET",
      url: `/branches/organization/${organizationGuid}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await xPosApiRequest(options);
      if (isOk(response.status)) {
        const branchesData = await response.data;
        setBranches(
          branchesData.map((item) => ({
            value: item.guid,
            label: item.name,
          }))
        );
      } else {
        showNotificationError("Ocurrio un error al cargar las Sucursales.");
      }
    } catch (error) {
      showNotificationError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (organizationValue) {
      fetchBranch(organizationValue.guid);
    }
  }, [organizationValue]);

  const clearFoliosInputs = () => {
    setTipoDTEValue("");
    setUmbralValue("");
    setNumberValue("");
  };

  const clearxPOSInputs = () => {
    setTaxIDValue("");
    setNombreValue("");
    setArrayFolios([]);
    setBranches([]);
    setBranchSelect("");
  };

  const handleSubmitxPOS = async (event) => {
    setLoading(true);
    event.preventDefault();
    if (pool === undefined) {
      const xPOSData = {
        country,
        taxId: taxIDValue,
        name: nombreValue,
        folios: arrayFolios,
        userLastAction: userInfo.correo,
        branchGuid: branchSelect?.value,
      };

      if (country === "gt") {
        // Verificar si ya existe el tipo de documento 13
        const docTypeExist = xPOSData.folios.some((folio) => folio.typeDte === 13);
        if (!docTypeExist) {
          xPOSData.folios.push({
            typeDte: 13, // Valor del tipo de documento 13
            typeUmbral: "quantity", // O 'percent' si es un porcentaje
            umbralValue: 200, // Valor inicial del umbral
          });
        }
      }

      const options = {
        method: "POST",
        url: `/xpos`,
        headers: {
          "Content-Type": "application/json",
        },
        data: xPOSData,
      };

      try {
        const response = await xPosApiRequest(options);
        if (isOk(response.status)) {
          setLoading(false);
          showNotificationSuccess("El xPOS se agregó exitosamente");
          clearxPOSInputs();
        } else {
          setLoading(false);
          showNotificationError(response.data);
        }
      } catch (error) {
        setLoading(false);
        showNotificationError(error);
      }
    } else {
      const xPOSData = {
        country,
        userLastAction: userInfo.correo,
        folios: arrayFolios,
        branchGuid: branchSelect?.value,
      };

      const options = {
        method: "PUT",
        url: `/xpos/${pool.guid}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: xPOSData,
      };

      try {
        const response = await xPosApiRequest(options);
        if (isOk(response.status)) {
          setLoading(false);
          showNotificationSuccess("El xPOS se actualizó exitosamente");
        } else {
          setLoading(false);
          showNotificationError(response.data);
        }
      } catch (error) {
        setLoading(false);
        showNotificationError(`Ocurrio un error al actualizar el xPOS`);
      }
    }
  };

  const handleSubmitFolio = () => {
    if (selectedIndex !== "") {
      const folio = arrayFolios[selectedIndex];
      const newArray = arrayFolios.filter((item) => item.typeDte !== folio.typeDte);
      const exist = newArray.filter((item) => item.typeDte === tipoDTEValue.value);

      if (exist.length > 0) {
        showNotificationError("El documento a asignar seleccionado ya existe.");
      } else {
        const folioData = {
          typeDte: tipoDTEValue.value,
          typeUmbral: umbralValue === "%" ? "percent" : "quantity",
          umbralValue: numberValue,
        };

        setArrayFolios([...newArray, folioData]);
        clearFoliosInputs();
        setSelectedIndex("");
      }
    } else {
      const exist = arrayFolios.filter((item) => item.typeDte === tipoDTEValue.value);

      if (exist.length > 0) {
        showNotificationError("El documento a asignar seleccionado ya existe.");
      } else {
        const folioData = {
          typeDte: tipoDTEValue.value,
          typeUmbral: umbralValue === "%" ? "percent" : "quantity",
          umbralValue: numberValue,
        };
        setArrayFolios([...arrayFolios, folioData]);
        clearFoliosInputs();
        setSelectedIndex("");
      }
    }
  };

  const handleUmbralChange = (event) => {
    setUmbralValue(event);
    setNumberValue("");
  };

  const handleNumberChange = (event) => {
    const { value } = event.target;
    if (
      value === "" ||
      (umbralValue === "%" && parseInt(value, 10) >= 1 && parseInt(value, 10) <= 20)
    ) {
      setNumberValue(value);
    } else if (value === "" || umbralValue === "Cantidad") {
      setNumberValue(value);
    }
  };

  const addDisabled = tipoDTEValue === "" || umbralValue === "" || numberValue === "";

  const handleDeleteFolio = (index) => {
    const folio = arrayFolios[index];
    if (folio.typeDte === 13 && country === "gt") {
      showNotificationError("Esta configuración es obligatoria, eliminación denegada.");
    } else {
      const newArray = arrayFolios.filter((item) => item.typeDte !== folio.typeDte);
      setArrayFolios([...newArray]);
      showNotificationSuccess("Folio eliminado correctamente");
    }
  };

  const handleEditFolio = (index) => {
    setSelectedIndex(index);
    const selectedFolio = arrayFolios[index];
    const tipoDTE = arrayTipoDTE.filter((item) => item.value === selectedFolio.typeDte);
    setTipoDTEValue(tipoDTE[0]);
    setUmbralValue(
      selectedFolio.typeUmbral === "%" || selectedFolio.typeUmbral === "percent" ? "%" : "Cantidad"
    );
    setNumberValue(selectedFolio.umbralValue);
  };

  let bttonDisabled =
    taxIDValue === "" || nombreValue === "" || arrayFolios.length === 0 || branchSelect === "";

  if (GsValue === false) {
    bttonDisabled = GsValue;
  }

  const countryName = CountryCodesToNames[country];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid display="flex" alignItems="center">
        <Tooltip title="Regresar" style={{ paddingLeft: 0 }} disableInteractive>
          <IconButton
            style={{ paddingLeft: 0 }}
            disableRipple
            component={Link}
            to={`/xPos/9${countryName}/9Pool`}
          >
            <ArrowBack style={{ cursor: "pointer", color: "black" }} />
          </IconButton>
        </Tooltip>
        <MDTypography fontWeight="bold" variant="h5" color="dark" noWrap>
          {pool !== undefined ? "Editar xPOS" : "Crear xPOS"}
        </MDTypography>
      </Grid>

      <MDBox py={1}>
        <MDBox pt={3} pb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <MDBox px={4} pt={5} pb={5}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      {pool ? (
                        <MDInput
                          label="Nombre de la organización"
                          type="text"
                          value={pool.organization.name}
                          size="medium"
                          variant="standard"
                          fullWidth
                          disabled
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 50 }}
                        />
                      ) : (
                        <SearchOrganization
                          country={country}
                          setTaxIDValue={setTaxIDValue}
                          showNotificationError={showNotificationError}
                          setLoading={setLoading}
                          required={pool === undefined}
                          variant="standard"
                          disabled={!!pool}
                          setGSValue={setGSValue}
                          setOrganizationValue={setOrganizationValue}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        label="Nombre del xPOS"
                        placeholder="Ingrese"
                        type="text"
                        value={nombreValue}
                        onChange={(event) => setNombreValue(event.target.value)}
                        size="medium"
                        variant="standard"
                        fullWidth
                        required
                        disabled={pool !== undefined}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ maxLength: 50 }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        options={branches}
                        size="medium"
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            label="Sucursal"
                            placeholder="Seleccione"
                            value={branchSelect}
                            variant="standard"
                            fullWidth
                            required
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                        value={branchSelect}
                        onChange={(event, newValue) => setBranchSelect(newValue)}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    style={{ border: "1px dashed", background: "#e9ecef", borderRadius: 8 }}
                    px={3}
                    pb={3}
                    pt={2}
                    mt={3}
                  >
                    <MDTypography fontWeight="bold" variant="h6" color="dark" noWrap>
                      Folios a asignar
                    </MDTypography>
                    <Grid pt={2} display="flex" justifyContent="space-between">
                      <Grid item xs={12} md={5}>
                        <Autocomplete
                          options={arrayTipoDTE}
                          size="medium"
                          renderInput={(params) => (
                            <MDInput
                              {...params}
                              label="Documento a asignar"
                              placeholder="Seleccione"
                              value={tipoDTEValue}
                              variant="standard"
                              fullWidth
                              required
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                          value={tipoDTEValue}
                          onChange={(event, newValue) => setTipoDTEValue(newValue)}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Autocomplete
                          options={["%", "Cantidad"]}
                          size="medium"
                          renderInput={(params) => (
                            <MDInput
                              {...params}
                              label="Umbral"
                              placeholder="Seleccione"
                              value={umbralValue}
                              variant="standard"
                              fullWidth
                              required
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                          value={umbralValue}
                          onChange={(event, newValue) => handleUmbralChange(newValue)}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <MDInput
                          label="Cuando quede"
                          placeholder="Ingrese"
                          type="number"
                          value={numberValue}
                          onChange={handleNumberChange}
                          size="medium"
                          variant="standard"
                          fullWidth
                          required
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                    </Grid>
                    <MDBox display="flex" justifyContent="flex-end" mt={4}>
                      <MDButton
                        variant="contained"
                        color="primary"
                        disabled={addDisabled}
                        onClick={() => {
                          handleSubmitFolio();
                        }}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "110px",
                          height: "35.5px",
                          borderRadius: 8,
                          backgroundColor: "#1971c2",
                        }}
                      >
                        <AddCircleOutlineIcon style={{ marginRight: 5, color: "white" }} />
                        <MDTypography
                          fontWeight="bold"
                          color="white"
                          py={1}
                          style={{ fontSize: 12 }}
                        >
                          AGREGAR
                        </MDTypography>
                      </MDButton>
                    </MDBox>
                  </Grid>
                  <Grid display="flex" justifyContent="space-between" align="left" pt={2} mt={1}>
                    <Grid width="50%" textAlign="left" ml={3}>
                      <MDTypography style={{ paddingBottom: 5 }} variant="h6" color="dark" noWrap>
                        Folios:
                      </MDTypography>
                      <Grid>
                        {arrayFolios.map((item, index) => {
                          const pos = index;
                          return (
                            <Grid
                              key={pos}
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              <DeleteIcon
                                onClick={() => handleDeleteFolio(pos)}
                                style={{ cursor: "pointer" }}
                                fontSize="small"
                              />
                              <EditIcon
                                onClick={() => handleEditFolio(pos)}
                                style={{ cursor: "pointer" }}
                                fontSize="small"
                              />
                              <Text
                                style={{
                                  paddingTop: "5px",
                                  paddingBottom: "5px",
                                  marginLeft: "10px",
                                }}
                              >
                                {item.typeDte}: Umbral: {item.umbralValue}
                                {item.typeUmbral === "percent" || item.typeUmbral === "%"
                                  ? "%"
                                  : ""}
                              </Text>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
              <MDBox display="flex" justifyContent="flex-end" mt={4}>
                <MDButton
                  variant="contained"
                  color="primary"
                  disabled={bttonDisabled}
                  onClick={handleSubmitxPOS}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "110px",
                    height: "35.5px",
                    borderRadius: 8,
                    backgroundColor: "#099268",
                  }}
                >
                  <SaveIcon style={{ marginRight: 5, color: "white" }} />
                  <MDTypography fontWeight="bold" color="white" py={1} style={{ fontSize: 12 }}>
                    GUARDAR
                  </MDTypography>
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <Notification notification={notification} setNotification={setNotification} />
        <Loading load={loading} />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
CrearXPOS.propTypes = {
  country: PropTypes.string.isRequired,
};
export default CrearXPOS;
