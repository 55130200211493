import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Autocomplete,
  TextField,
  useMediaQuery,
} from "@mui/material";
import xPosApiRequest from "api/xPosApiRequest";
import PropTypes from "prop-types";
import { useTheme } from "@emotion/react";
import useTypeDteByCountry from "./useTypeDteByCountry";

function ModalLiberarFolios({ xpos, onClose, showNotificationSuccess, showNotificationError }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [tipoDocumento, setTipoDocumento] = useState(null);
  const [opcionesTipoDocumento, setOpcionesTipoDocumento] = useState([]);
  const descripciones = useTypeDteByCountry(xpos?.country);

  useEffect(() => {
    async function cargarOpciones() {
      if (descripciones.length > 0) {
        const opciones = descripciones.filter((d) =>
          xpos.folios.some((folio) => folio.typeDte === d.value)
        );
        setOpcionesTipoDocumento(opciones);
      }
    }
    cargarOpciones();
  }, [descripciones, xpos]);

  const handleChangeTipoDocumento = (event, newValue) => {
    setTipoDocumento(newValue);
  };

  const handleLiberarFolios = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };

      const dataRequest = {
        xposId: xpos.guid,
        environment: "release",
        typeDte: tipoDocumento.value,
        range: "CURRENT",
      };

      const options = {
        method: "POST",
        url: `/xpos/xposSubRangeRelease`,
        headers,
        data: dataRequest,
      };

      const response = await xPosApiRequest(options);
      if (!response.ok) {
        showNotificationError(`Error al liberar folios: ${response.data}`);
      } else {
        showNotificationSuccess("Folios liberados correctamente");
      }
    } catch (error) {
      showNotificationError("Error al liberar folios:", error);
    } finally {
      onClose();
    }
  };

  return (
    <Dialog
      open
      onClose={onClose}
      fullScreen={fullScreen}
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Liberar Folios para {xpos.name}</DialogTitle>
      <DialogContent>
        <Autocomplete
          options={opcionesTipoDocumento}
          value={tipoDocumento}
          onChange={handleChangeTipoDocumento}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField {...params} label="Tipo de Documento" variant="outlined" />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleLiberarFolios} disabled={!tipoDocumento}>
          Liberar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalLiberarFolios.propTypes = {
  xpos: PropTypes.objectOf(PropTypes.any).isRequired,
  onClose: PropTypes.func.isRequired,
  showNotificationSuccess: PropTypes.func.isRequired,
  showNotificationError: PropTypes.func.isRequired,
};

export default ModalLiberarFolios;
