/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";

// Default Components
import MDTypography from "components/MDTypography";

export default function data(
  jsonData,
  chekLogs,
  copyLinkToClipboard,
  downloadZip,
  setViewModalDatosRespaldo,
  deleteJsonData,
  getSasUrlJsonData,
  setViewModalTimeExpirationRespaldo
) {
  const getRows = () => {
    const elements = [];
    for (let index = 0; index < jsonData.length; index += 1) {
      elements.push({
        taxId: jsonData[index].taxId,
        pais: jsonData[index].pais ? jsonData[index].pais.nombre : "*****",
        meses: `${jsonData[index].mesesActuales}/${jsonData[index].mesesTotales}`,
        tipoRespaldo: (
          <MDTypography variant="subtitle2" fontWeight="light" color="text">
            {jsonData[index].tipoRespaldo.nombre}{" "}
            <Tooltip
              title="Ver Datos Respaldo"
              disableInteractive
              onClick={() => setViewModalDatosRespaldo(jsonData[index])}
            >
              <Icon>visibility</Icon>
            </Tooltip>
          </MDTypography>
        ),
        fechaCreacion: new Date(jsonData[index].fechaCreacion).toLocaleDateString("es-ES"),
        usuario: jsonData[index].usuario,
        tiempoExpiracion: (
          <MDTypography variant="subtitle2" fontWeight="light" color="text">
            {jsonData[index].timeExpiration}{" "}
            <Tooltip
              title="Actualizar Tiempo de Expiración"
              disableInteractive
              onClick={() => setViewModalTimeExpirationRespaldo(jsonData[index])}
            >
              <Icon>visibility</Icon>
            </Tooltip>
          </MDTypography>
        ),
        zip: (
          <Tooltip disableInteractive title="">
            <Switch
              checked={jsonData[index].zip}
              inputProps={{ "aria-label": "controlled" }}
              disabled
            />
          </Tooltip>
        ),
        action: (
          <MDTypography color="text">
            <Tooltip
              title="Ver Log"
              disableInteractive
              onClick={() => {
                chekLogs(jsonData[index]);
              }}
            >
              <Icon>troubleshoot</Icon>
            </Tooltip>{" "}
            <Tooltip
              title="Download"
              disableInteractive
              onClick={() => {
                downloadZip(jsonData[index]);
              }}
              sx={{ display: jsonData[index].zip === true ? "inline" : "none" }}
            >
              <Icon>download</Icon>
            </Tooltip>{" "}
            <Tooltip
              title="Informar link del respaldo (url)"
              disableInteractive
              onClick={() => {
                copyLinkToClipboard(jsonData[index].url);
              }}
              sx={{ display: jsonData[index].tipoRespaldo.id === "01" ? "none" : "inline" }}
            >
              <Icon>insert_link</Icon>
            </Tooltip>
            <Tooltip
              title="Url del respaldo compartida"
              disableInteractive
              onClick={() => {
                getSasUrlJsonData(jsonData[index]);
              }}
              sx={{ display: jsonData[index].tipoRespaldo.id === "01" ? "inline" : "none" }}
            >
              <Icon>insert_link</Icon>
            </Tooltip>
            <Tooltip
              title="Eliminar Elemento"
              disableInteractive
              onClick={() => deleteJsonData(jsonData[index])}
            >
              <Icon>delete</Icon>
            </Tooltip>
          </MDTypography>
        ),
      });
    }

    return elements;
  };

  return {
    columns: [
      { Header: "Tax Id", accessor: "taxId", align: "center" },
      { Header: "Pais", accessor: "pais", align: "center" },
      { Header: "Meses", accessor: "meses", align: "center" },
      { Header: "Tipo Respaldo", accessor: "tipoRespaldo", align: "center" },
      { Header: "Fecha Creación", accessor: "fechaCreacion", align: "center" },
      { Header: "Zip", accessor: "zip", align: "center" },
      { Header: "Usuario", accessor: "usuario", align: "center" },
      { Header: "Fecha Expiración", accessor: "tiempoExpiracion", align: "center" },
      { Header: "Accion", accessor: "action", align: "center" },
    ],

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
  chekLogs: PropTypes.func.isRequired,
  getLinkToClipboard: PropTypes.func.isRequired,
  downloadZip: PropTypes.func.isRequired,
  setViewModalDatosRespaldo: PropTypes.func.isRequired,
  deleteJsonData: PropTypes.func.isRequired,
  getSasUrlJsonData: PropTypes.func.isRequired,
  setViewModalTimeExpirationRespaldo: PropTypes.func.isRequired,
};
