import EnexRUTImpuestoNegativo from "views/soporte/Enex_RUTImpuestoNegativo";
import EnexGestionImpuestoNegativo from "views/soporte/Enex_GestionImpuestoNegativ";
import XDocGlobalCargaXSLTPRD from "views/soporte/XDocGlobal_CargaXSLTPRD";
// import XDocGlobalCargaXSLTSBX from "views/soporte/XDocGlobal_CargaXSLTSBX";
import ContingenciaDIAN from "views/soporte/ContingenciaDIAN";
import ContingenciaPY from "views/soporte/ContingenciaPY";
import AltaEmpresaProd from "views/consultoria/AltaEmpresaProd";
import AltaEmpresaSandbox from "views/consultoria/AltaEmpresaSandbox";
import BloquearEmision from "views/finanzas/GestionCliente/BloquearEmision";
import BusquedaCorreosRecibidos from "views/soporte/ConfiguracionEmpresa/BusquedaCorreosRecibidos";
import BusquedaAdjuntosRecibidos from "views/soporte/ConfiguracionEmpresa/BusquedaAdjuntosRecibidos";
import ChileDesbloquear from "views/consultoria/API/ChileDesbloquear";
import ReprocesarDocumento from "views/soporte/ReprocesarDocumentos";
import ApiKey from "views/soporte/ConfiguracionEmpresa/ApiKey";
import ApiKeySandbox from "views/soporte/ApiKeySandBox";
import Conciliacion from "views/consultoria/ConfiguracionEmpresa/conciliacion";
import SingleSingOnPRD from "views/varios/SingleSingOnPRD";
import SingleSingOnSBX from "views/varios/SingleSingOnSBX";
import ActualizarRespuesta from "views/consultoria/API/ActualizarRespuesta";
import PoliticasContraseniaPRD from "views/consultoria/ConfiguracionEmpresa/politicasContraseniaPRD";
import PoliticasContraseniaSBX from "views/consultoria/ConfiguracionEmpresa/politicasContraseniaSBX";
import DocRestringidos from "views/consultoria/ConfiguracionEmpresa/DocRestringidos";
import GlobalIdDuplicados from "views/soporte/GlobalIdDuplicados";
import PlantillasJasperCrystalPRD from "views/producto/PlantillasJasperCrystal";
import PlantillasJasperCrystalSBX from "views/producto/PlantillasJasperCrystalSBX";
import ListadoFuncionalidades from "views/producto/ListadoFuncionalidades";
import ComunicacionPAC from "views/varios/ComunicacionPAC";
import ErroresID from "views/varios/ErroresID";
import XMLHistoricos from "views/producto/XMLHistoricos";
import XDocGlobalPermisosXSLT from "views/soporte/XDocGlobal_PermisosXSLT";

import ConfigGlobal from "views/admin/xpos/configGlobal";
import Organizaciones from "views/admin/xpos/organizaciones";
import Pool from "views/admin/xpos/pool";
import Logs from "views/admin/xpos/logsConfig";

// import ActualizarConfig from "views/admin/xpos/chile/configGlobal/actualizar-configuracion";
import Versiones from "views/admin/xpos/versiones";
import CrearVersion from "views/admin/xpos/versiones/crear-version";
// import CrearOrganizacion from "views/admin/xpos/chile/organizaciones/crear-organizacion";
// import CrearXPOS from "views/admin/xpos/chile/pool/crear-pool";
import Contingencias from "views/admin/xpos/contingencias";
// import LogsHistory from "views/admin/xpos/chile/logsConfig/historyLogs";
import Metricas from "views/admin/xpos/metricas";
import ConfiguracionGeneralSS from "views/consultoria/ConfiguracionesSmartSupply";
import Clients from "views/admin/xdoc/clients";
import TaxEntities from "views/admin/xdoc/taxentity";
import Versions from "views/admin/xdoc/versions";
import PrerequisiteInstallers from "views/admin/xdoc/prerequisites";
import UpdatesList from "views/admin/xdoc/updates/listview";
import GestorFolioConfiguraciones from "views/varios/GestorFolio/Configuraciones";
import Radian from "views/comercial/Radian";
import PartnerCrearEmpresa from "views/partners/CrearEmpresa";
import ManagerEmpresa from "views/varios/Manager/CrearEmpresa";
import ManagerGadget from "views/manager/Gadget";
import ManagerUsuarios from "views/manager/usuarios";
import ManagerVinculoEmpresa from "views/manager/vinculo";
import ManagerShorcuts from "views/manager/shortcut";
import ContingenciaSV from "views/soporte/ContingenciaSV";
import Calculadora from "views/producto/Calculadora";
import InstallationsList from "../views/admin/xdoc/installations";
import AreasList from "../views/admin/xdoc/areas";

function getComponent(id) {
  switch (id) {
    case 1:
      return <EnexRUTImpuestoNegativo />;
    case 2:
      return <EnexGestionImpuestoNegativo />;
    case 3:
      return <XDocGlobalCargaXSLTPRD />;
    case 4:
      return <ContingenciaDIAN />;
    case 5:
      return <ContingenciaSV />;
    case 6:
      return <AltaEmpresaProd />;
    case 7:
      return <BloquearEmision />;
    case 8:
      return <AltaEmpresaSandbox />;
    case 9:
      return <ContingenciaPY />;
    case 10:
      return <BusquedaCorreosRecibidos />;
    case 11:
      return <ChileDesbloquear />; // deprecated
    case 12:
      return <ReprocesarDocumento />;
    case 13:
      return <BusquedaAdjuntosRecibidos />;
    case 14:
      return <ApiKey />;
    case 15:
      return <Conciliacion />;
    case 16:
      return <SingleSingOnPRD />;
    case 17:
      return <ActualizarRespuesta />;
    case 18:
      return <ApiKeySandbox />;
    case 19:
      return <PoliticasContraseniaPRD />;
    case 20:
      return <DocRestringidos />;
    case 21:
      return <GlobalIdDuplicados />;
    case 35:
      return <Radian />;
    case 36:
      return <XDocGlobalPermisosXSLT />;
    case 37:
      return <GestorFolioConfiguraciones />;
    case 38:
      return <XMLHistoricos />;
    case 39:
      return <SingleSingOnSBX />;
    case 40:
      return <PoliticasContraseniaSBX />;
    // case 41:
    //   return <XDocGlobalCargaXSLTSBX />;
    case 43:
      return <PlantillasJasperCrystalPRD />;
    case 44:
      return <PlantillasJasperCrystalSBX />;
    case 45:
      return <ListadoFuncionalidades />;
    case 46:
      return <ComunicacionPAC />;
    case 47:
      return <ErroresID />;
    case 48:
      return <ConfigGlobal country="cl" />;
    // case 49:
    //   return <ActualizarConfig />;
    case 50:
      return <Versiones />;
    case 51:
      return <CrearVersion />;
    case 52:
      return <Organizaciones country="cl" />;
    // case 53:
    //   return <CrearOrganizacion />;
    case 54:
      return <Pool country="cl" />;
    // case 55:
    //   return <CrearXPOS />;
    case 56:
      return <Logs country="cl" />;
    // case 57:
    //   return <LogsHistory />;
    case 58:
      return <Metricas />;
    case 59:
      return <AreasList />;
    case 60:
      return <InstallationsList />;
    case 61:
      return <Clients />;
    case 62:
      return <TaxEntities />;
    case 63:
      return <Versions />;
    case 64:
      return <PrerequisiteInstallers />;
    case 65:
      return <UpdatesList />;
    case 66:
      return <ConfigGlobal country="co" />;
    case 67:
      return <Organizaciones country="co" />;
    case 68:
      return <Pool country="co" />;
    case 69:
      return <Logs country="co" />;
    case 70:
      return <Contingencias country="co" />;
    case 71:
      return <PartnerCrearEmpresa />;
    case 72:
      return <ManagerEmpresa />;
    case 73:
      return <ConfigGlobal country="py" />;
    case 74:
      return <Organizaciones country="py" />;
    case 75:
      return <Pool country="py" />;
    case 76:
      return <Logs country="py" />;
    case 77:
      return <ManagerGadget />;
    case 78:
      return <ManagerUsuarios />;
    case 79:
      return <ManagerVinculoEmpresa />;
    case 80:
      return <ManagerShorcuts />;
    case 81:
      return <ConfigGlobal country="gt" />;
    case 82:
      return <Organizaciones country="gt" />;
    case 83:
      return <Pool country="gt" />;
    case 84:
      return <Logs country="gt" />;
    case 86:
      return <Contingencias country="gt" />;
    case 88:
      return <ConfiguracionGeneralSS />;
    case 94:
      return <Calculadora />;
    default:
      console.log("Component not found");
      return null;
  }
}

export default getComponent;
