/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDDatePicker from "components/MDDatePicker";
import Loading from "components/Loading";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// API Request
import APIRequest from "api/request";
import APIRequestGeneric from "api/requestGeneric";

function XMLHistoricosForm() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const host = process.env.REACT_APP_API_URL;
  const basicAuth = `${process.env.REACT_APP_API_ENEX_USER}:${process.env.REACT_APP_API_ENEX_PASSWORD}`;
  const basicAuthorization = `Basic ${btoa(basicAuth)}`;

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [load, setLoad] = useState(false);
  // const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();
  const {
    state: { route, element },
  } = useLocation();
  const navigate = useNavigate();

  const [elementIsNew, setElementIsNew] = useState(true);
  const [showAdditional, setShowAdditional] = useState(false);
  const [newValuePais, setNewValuePais] = useState(null);
  const [newValueTaxId, setNewValueTaxId] = useState("");
  const [newValueFechaInicio, setNewValueFechaInicio] = useState("");
  const [newValueFechaFin, setNewValueFechaFin] = useState("");
  const [newValueTipoRespaldo, setNewValueTipoRespaldo] = useState(null);
  const [newValueTipoComunicacion, setNewValueTipoComunicacion] = useState(null);
  const [newValueURL, setNewValueURL] = useState("");
  const [newValueContainer, setNewValueContainer] = useState("");
  const [newValueZip, setNewValueZip] = useState(false);
  const [newTimeValueExpiration, setNewValueTimeExpiration] = useState(6);

  const handleTimeValueExpirationChange = (value) => {
    if (value >= 1 && value <= 6) {
      setNewValueTimeExpiration(value);
    } else {
      // eslint-disable-next-line no-use-before-define
      showNotificationError("El tiempo de expiracion debe estar en el rango de 1 a 6 meses");
    }
  };
  const [selectData, setSelectData] = useState({
    pais: [
      { id: "ar", nombre: "Argentina" },
      { id: "gt", nombre: "Guatemala" },
    ],
    tipoRespaldo: [
      { id: "01", nombre: "Gosocket" },
      { id: "02", nombre: "Cliente" },
    ],
    tipoComunicacion: [
      { id: 1, nombre: "Azure" },
      { id: 2, nombre: "Amazon" },
    ],
  });

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };

  const closeModal = () => {
    setNewValuePais(null);
    setNewValueTaxId("");
    setNewValueFechaInicio("");
    setNewValueFechaFin("");
    setNewValueTipoRespaldo(null);
    setNewValueTipoComunicacion(null);
    setNewValueURL("");
    setNewValueContainer("");
    setNewValueTimeExpiration("");
  };

  const crearRegistroLocal = async (tempElement) => {
    const body = {
      id: tempElement.id,
      status: tempElement.status,
      taxId: tempElement.taxId,
      pais: tempElement.pais.id,
      mesesTotales: 0,
      mesesActuales: 0,
      tipoRespaldo: tempElement.tipoRespaldo.id === "01" ? 1 : 2,
      tipoComunicacion: tempElement.tipoComunicacion === null ? 0 : tempElement.tipoComunicacion.id,
      fechaInicio: tempElement.fechaInicio,
      fechaFin: tempElement.fechaFin,
      fechaCreacion: tempElement.fechaCreacion,
      contenedor: tempElement.contenedor,
      backupUrl: tempElement.url,
      zip: tempElement.zip,
      usuarioId: userInfo.id,
      timeExpiration: tempElement.timeExpiration,
    };

    const apiResponse = await APIRequest("POST", "/api/XmlHistoricos", body);

    if (apiResponse.status === 201 || apiResponse.status === 200 || apiResponse.ok) {
      return true;
    }

    return false;
  };
  const saveDataModal = async () => {
    if (!elementIsNew) {
      showNotificationSuccess("Por Implementar");
    } else if (newValueFechaInicio === "" || newValueFechaFin === "") {
      showNotificationError("Ingrese las dos fechas.");
    } else if (new Date(newValueFechaFin) >= new Date(newValueFechaInicio)) {
      const startDate = new Date(newValueFechaInicio);
      const endDate = new Date(newValueFechaFin);
      const body = {
        taxId: newValueTaxId,
        country: newValuePais.id,
        start: `${startDate.getFullYear()}-${(startDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${startDate.getDate().toString().padStart(2, "0")}T00:00:00Z`,
        end: `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${endDate.getDate().toString().padStart(2, "0")}T00:00:00Z`,
        backupProvider: newValueTipoRespaldo.id === "01" ? 0 : newValueTipoComunicacion.id,
        backupUrl: newValueURL,
        // URL AWS: AccessKey=xxxxxx;SecretKey=xxxxxx;Region=us-east-1
        backupContainer: newValueContainer,
        // Container AWS: bucket
        documentTypes: ["-1"], // All
        user: userInfo.correo,
        zip: newValueZip,
        timeExpiration: newTimeValueExpiration,
      };

      try {
        // const urlComplete =
        // ("https://apihistoricaldocssbx.blackhill-79102964.centralus.azurecontainerapps.io/api/RequestBackup/Generate");
        const urlComplete = `${host}/api/RequestBackup/Generate`;
        // eslint-disable-next-line prettier/prettier
        const apiResponse = await APIRequestGeneric("POST", urlComplete, body, false, basicAuthorization);       
        if (apiResponse.status === 200 || apiResponse.status === 201) {
          const dataResponse = await apiResponse.json();
          if (dataResponse.success) {
            // const fechaInicio = startDate.getTime();
            // const fechaFin = endDate.getTime();
            const fechaActual = new Date();
            // const diffTotal = fechaFin - fechaInicio;
            // let diffActual = fechaActual - fechaInicio;
            // diffActual = diffActual > diffTotal ? diffTotal : diffActual;

            const tempElement = {
              id: elementIsNew ? dataResponse.data.id : element.id, // b77419a9-c170-44af-b040-bcb6c7503d6a
              status: dataResponse.status,
              taxId: newValueTaxId,
              pais: newValuePais,
              mesesTotales: 0, // Math.round(diffTotal / (1000 * 60 * 60 * 24 * 30)),
              mesesActuales: 0, // Math.round(diffActual / (1000 * 60 * 60 * 24 * 30)),
              tipoRespaldo: newValueTipoRespaldo,
              tipoComunicacion: newValueTipoRespaldo.id === "01" ? null : newValueTipoComunicacion, // Gosocket
              fechaInicio: body.start,
              fechaFin: body.end,
              fechaCreacion: `${fechaActual.getFullYear()}-${(fechaActual.getMonth() + 1)
                .toString()
                .padStart(2, "0")}-${fechaActual.getDate().toString().padStart(2, "0")}`,
              usuario: userInfo.correo,
              url: newValueTipoRespaldo.id === "01" ? "" : newValueURL, // Gosocket
              contenedor: newValueTipoRespaldo.id === "01" ? "" : newValueContainer, // Gosocket
              zip: newValueZip,
              timeExpiration: newTimeValueExpiration,
            };
            if (elementIsNew) {
              const registroCreado = await crearRegistroLocal(tempElement);

              if (registroCreado) {
                closeModal();
                showNotificationSuccess("Se guardó el registro exitosamente");
                navigate(route, { state: { newElement: tempElement } });
              } else {
                showNotificationError("Ocurrió un error inesperado al crear el registro local");
              }
            } else {
              showNotificationSuccess("Se actualizó la información exitosamente");
              navigate(route, { state: { elementChanges: tempElement } });
            }
          } else {
            const errorMessage = {
              code: apiResponse.status,
              message: `Error: ${apiResponse.status}, el campo success es ${dataResponse.success}`,
            };
            throw errorMessage;
          }
        } else {
          const dataResponse = await apiResponse.json();
          const message = apiResponse.status === 500 ? dataResponse.detail : apiResponse.status;
          showNotificationError(`Ocurrió un error al generar la solicitud: ${message}`);
        }
      } catch (e) {
        showNotificationError(`Ocurrió un error en: ${e.message}`);
      }
    } else if (new Date(newValueFechaFin) < new Date(newValueFechaInicio)) {
      showNotificationError("La fecha fin no puede ser menor a la fecha de inicio.");
    }
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const setEditJsonData = (jsonElement) => {
    if (element) {
      setNewValuePais(jsonElement.pais);
      setNewValueTaxId(jsonElement.taxId);
      setNewValueFechaInicio(jsonElement.fechaInicio);
      setNewValueFechaFin(jsonElement.fechaFin);
      setNewValueTipoRespaldo(jsonElement.tipoRespaldo);
      setNewValueTipoComunicacion(jsonElement.tipoComunicacion);
      setNewValueURL(jsonElement.url);
      setNewValueContainer(jsonElement.contenedor);
      setNewValueTimeExpiration(jsonElement.timeExpiration);

      if (jsonElement.tipoRespaldo === null || jsonElement.tipoRespaldo === undefined) {
        setShowAdditional(false);
      } else if (jsonElement.tipoRespaldo.id === "02") {
        // Cliente
        setShowAdditional(true);
      } else {
        setShowAdditional(false);
      }
    }
  };

  const getCountriesData = async () => {
    const tempJsonData = [];
    const apiResponse = await (await APIRequest("GET", "/api/Pais/Todos", null)).json();

    for (let index = 0; index < apiResponse.length; index += 1) {
      tempJsonData.push({
        id: apiResponse[index].codigo.toLowerCase(),
        nombre: apiResponse[index].nombre,
      });
    }

    setSelectData({
      pais: tempJsonData,
      tipoRespaldo: selectData.tipoRespaldo,
      tipoComunicacion: selectData.tipoComunicacion,
    });

    return tempJsonData;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoad(true);
    await saveDataModal();
    setLoad(false);
  };

  useEffect(async () => {
    await getCountriesData();
    setElementIsNew(!element);
    setEditJsonData(element);
  }, [pathname]);

  return (
    <DashboardLayout>
      <DashboardNavbar breadCrumb={route.split("/").slice(1)} />
      <MDBox pt={3} pb={3} component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDBox pb={4}>
              <MDTypography variant="subtitle" fontWeight="bold">
                <Icon
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                  onClick={() => navigate(route)}
                >
                  arrow_back
                </Icon>
                {"   "}
                Se permite crear respaldo de XML históricos emitidos por clientes.
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
                <Grid container spacing={3} justifyContent="left" alignItems="left" height="100%">
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Autocomplete
                      value={newValuePais}
                      onChange={async (event, newValue) => {
                        setNewValuePais(newValue);
                      }}
                      options={selectData.pais}
                      getOptionLabel={(option) => option.nombre}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      disabled={!elementIsNew}
                      renderInput={(params) => (
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Pais"
                          fullWidth
                          required
                          InputLabelProps={{ shrink: true }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Tax ID"
                      fullWidth
                      required
                      disabled={!elementIsNew}
                      InputLabelProps={{ shrink: true }}
                      value={newValueTaxId}
                      onChange={(e) => {
                        setNewValueTaxId(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDDatePicker
                      input={{
                        variant: "standard",
                        label: "Fecha Inicio",
                        fullWidth: true,
                        required: true,
                        InputLabelProps: { shrink: true },
                      }}
                      value={newValueFechaInicio}
                      onChange={(e) => {
                        setNewValueFechaInicio(e[0]);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDDatePicker
                      input={{
                        variant: "standard",
                        label: "Fecha Fin",
                        fullWidth: true,
                        required: true,
                        InputLabelProps: { shrink: true },
                      }}
                      value={newValueFechaFin}
                      onChange={(e) => {
                        setNewValueFechaFin(e[0]);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDBox
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="center"
                      width={{ xs: "100%", sm: "auto" }}
                      mt={{ xs: 1, sm: 0 }}
                    >
                      <MDBox lineHeight={0} mx={2}>
                        <MDTypography variant="button" color="text">
                          ZIP
                        </MDTypography>
                      </MDBox>
                      <MDBox mr={1}>
                        <Switch
                          checked={newValueZip}
                          onChange={() => setNewValueZip(!newValueZip)}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </MDBox>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Autocomplete
                      value={newValueTipoRespaldo}
                      onChange={(event, newValue) => {
                        setNewValueTipoRespaldo(newValue);
                        if (newValue === null || newValue === undefined) {
                          setShowAdditional(false);
                        } else if (newValue.id === "02") {
                          // Cliente
                          setShowAdditional(true);
                        } else {
                          setShowAdditional(false);
                        }
                      }}
                      options={selectData.tipoRespaldo}
                      getOptionLabel={(option) => option.nombre}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Tipo Respaldo"
                          fullWidth
                          required
                          InputLabelProps={{ shrink: true }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Tiempo Expiracion (meses)"
                      fullWidth
                      required
                      disabled={!elementIsNew}
                      InputLabelProps={{ shrink: true }}
                      value={newTimeValueExpiration}
                      onChange={(e) => handleTimeValueExpirationChange(Number(e.target.value))}
                      min="1"
                      max="6"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <Autocomplete
                      value={newValueTipoComunicacion}
                      onChange={(event, newValue) => {
                        setNewValueTipoComunicacion(newValue);
                      }}
                      options={selectData.tipoComunicacion}
                      getOptionLabel={(option) => option.nombre}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <MDInput
                          variant="standard"
                          type="text"
                          label="Tipo Comunicacion"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          {...params}
                        />
                      )}
                      sx={{ display: showAdditional ? "flex" : "none" }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={3}
                    sx={{ display: showAdditional ? "flex" : "none" }}
                  >
                    <MDInput
                      variant="standard"
                      type="text"
                      label="URL"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={newValueURL}
                      onChange={(e) => {
                        setNewValueURL(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={3}
                    sx={{ display: showAdditional ? "flex" : "none" }}
                  >
                    <MDInput
                      variant="standard"
                      type="text"
                      label="Contenedor"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={newValueContainer}
                      onChange={(e) => {
                        setNewValueContainer(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="left" alignItems="left" height="100%">
              <Grid item xs={12} sm={12} md={6} lg={6} xl={8} />
              <Grid item xs={12} sm={12} md={6} lg={3} xl={2} container justifyContent="flex-end">
                <MDButton
                  variant="contained"
                  type="submit"
                  sx={{
                    background: "linear-gradient(45deg, #099268 30%, #099268 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>save</Icon>&nbsp;Guardar
                </MDButton>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={2} container justifyContent="flex-end">
                <MDButton
                  variant="contained"
                  onClick={() => navigate(route)}
                  sx={{
                    background: "linear-gradient(45deg, #CC0000 30%, #CC0000 90%)",
                    color: "#FFFFFF",
                  }}
                >
                  <Icon>cancel</Icon>&nbsp;Cancelar
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      <Loading load={load} />
      <Footer />
    </DashboardLayout>
  );
}

export default XMLHistoricosForm;
