/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import data from "views/consultoria/ConfiguracionesSmartSupply/data";

// API Request
import APIRequest from "api/request";

function ConfiguracionGeneralSS() {
  const currentDate = new Date();
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  const [notification, setNotification] = useState({
    color: "warning",
    icon: "",
    title: "",
    content: "",
    dateTime: "",
    show: false,
  });
  const [load, setLoad] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const { pathname } = useLocation();

  // eslint-disable-next-line no-unused-vars
  const [newValuePais, setNewValuePais] = useState(null);
  const [newValueTaxId, setNewValueTaxId] = useState("");
  const [newValueLoadDocUpload, setNewValueLoadDocUpload] = useState(false);

  const [selectData, setSelectData] = useState({
    pais: [
      { id: "cr", nombre: "Costa rica" },
      { id: "ec", nombre: "Ecuador" },
      { id: "pe", nombre: "Perú" },
      { id: "cl", nombre: "Chile" },
      { id: "mx", nombre: "México" },
      { id: "gt", nombre: "Guatemala" },
      { id: "co", nombre: "Colombia" },
      { id: "pa", nombre: "Panamá" },
    ],
  });

  const isDefaultDate = (dateToCheck) => {
    const defaultDate = new Date("0001-01-01T00:00:00");
    if (format(dateToCheck, "MM/dd/yy H:mma") === format(defaultDate, "MM/dd/yy H:mma")) {
      return true;
    }
    return false;
  };

  const configNotification = (vColor, vIcon, vTitle, vContent, vDateTime, vShow) => {
    setNotification({
      color: vColor,
      icon: vIcon,
      title: vTitle,
      content: vContent,
      dateTime: vDateTime,
      show: vShow,
    });
  };
  const loading = (
    <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10000 }} open={load}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
  const showNotificationError = (message) => {
    configNotification("error", "warning", "Notificación", message, "", true);
  };
  const showNotificationSuccess = (message) => {
    configNotification("warning", "check", "Notificación", message, "", true);
  };
  const setFuncionalities = () => {
    setNewValueLoadDocUpload(!newValueLoadDocUpload);
  };

  const getJsonData = async (countries) => {
    const tempJsonData = [];
    // setLoad(true);
    try {
      const getAllUploadDocPermApi = await (
        await APIRequest("GET", "/Api/Settings/GetAllUploadDocumentPermissions", null)
      ).json();

      for (let index = 0; index < getAllUploadDocPermApi.length; index += 1) {
        tempJsonData.push({
          taxId: getAllUploadDocPermApi[index].accountCode,
          UploadDocuPermission: getAllUploadDocPermApi[index].enable,
          usuario: getAllUploadDocPermApi[index].userUpdated,
          pais: countries[
            countries
              .map((item) => item.id)
              .indexOf(getAllUploadDocPermApi[index].countryId.trim().toLowerCase()) // Country,
          ],
          fechaMod: isDefaultDate(getAllUploadDocPermApi[index].updatedAt)
            ? "..."
            : format(getAllUploadDocPermApi[index].updatedAt, "MM/dd/yy H:mma"),
        });
      }
      setJsonData(tempJsonData);
    } catch (e) {
      console.log(`Ocurrió un error : ${e.message}`);
    }
    setLoad(false);
  };

  const saveDataModal = async () => {
    setLoad(true);

    try {
      const index = jsonData.map((item) => item.taxId).indexOf(newValueTaxId);

      if (index < 0) {
        const tempJsonData = jsonData;
        const body = {
          AccountCode: newValueTaxId,
          UserUpdated: userInfo.correo,
          CountryId: newValuePais.id,
          Enable: newValueLoadDocUpload,
          UpdatedAt: currentDate,
        };
        const apiResponse = await APIRequest(
          "POST",
          "/Api/Settings/UpdateUploadDocumentPermission",
          body
        );
        const jsonResponse = await apiResponse.json();
        if (apiResponse.status === 200) {
          const element = {
            pais: newValuePais,
            taxId: newValueTaxId,
            usuario: userInfo.correo,
            UploadDocuPermission: newValueLoadDocUpload,
            fechaMod: format(currentDate, "MM/dd/yy H:mma"),
          };
          setNewValueTaxId("");
          setNewValuePais("");
          tempJsonData.push(element);
        } else {
          const errorMessage = {
            code: apiResponse.status,
            message: `Error: ${
              jsonResponse === null || jsonResponse === undefined
                ? apiResponse.status
                : jsonResponse
            }`,
          };
          throw errorMessage;
        }
        setJsonData(tempJsonData);
        showNotificationSuccess(
          `Se agrego el permiso para carga de documentos a la empresa ${newValueTaxId} exitosamente`
        );
      } else {
        showNotificationSuccess("Existe el permiso para la empresa ingresada");
      }
    } catch (e) {
      showNotificationError(`Ocurrió un error en el perfil: ${e.message}`);
    }
    setLoad(false);
  };

  const renderNotificacion = (
    <MDSnackbar
      color={notification.color}
      icon={notification.icon}
      title={notification.title}
      content={notification.content}
      dateTime={notification.dateTime}
      open={notification.show}
      close={() => configNotification(notification.color, "", "", "", "", false)}
      onClose={() => configNotification(notification.color, "", "", "", "", false)}
      bgWhite
    />
  );

  const getCountriesData = async () => {
    const tempJsonData = [];
    const apiResponse = await (await APIRequest("GET", "/api/Pais/Todos", null)).json();

    if (apiResponse.length > 0) {
      for (let index = 0; index < apiResponse.length; index += 1) {
        tempJsonData.push({
          id: apiResponse[index].codigo.toLowerCase(),
          nombre: apiResponse[index].nombre,
        });
      }

      setSelectData({
        pais: tempJsonData,
        // status: selectData.status,
      });
    }

    return tempJsonData;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    saveDataModal();
  };

  const updateUploadDocPermJsonData = async (taxId) => {
    setLoad(true);
    const tempJsonData = jsonData;
    const index = tempJsonData.map((item) => item.taxId).indexOf(taxId);
    const body = {
      AccountCode: tempJsonData[index].taxId, //
      Enable: !tempJsonData[index].UploadDocuPermission,
      UserUpdated: userInfo.correo,
      CountryId: tempJsonData[index].pais.id, //
      UpdatedAt: currentDate,
    };
    try {
      const apiResponse = await APIRequest(
        "POST",
        "/Api/Settings/UpdateUploadDocumentPermission",
        body
      );
      if (apiResponse.status === 200) {
        // tempJsonData[index].taxId = tempJsonData[index].taxId;
        tempJsonData[index].UploadDocuPermission = !tempJsonData[index].UploadDocuPermission;
        tempJsonData[index].usuario = userInfo.correo;
        tempJsonData[index].fechaMod = format(currentDate, "MM/dd/yy H:mma");
      } else {
        const errorMessage = {
          code: apiResponse.status,
          message: `Error: ${apiResponse.status}`,
        };
        throw errorMessage;
      }
      setJsonData(tempJsonData);
      showNotificationSuccess(`Se actualizó el tax ${tempJsonData[index].taxId} exitosamente`);
    } catch (e) {
      configNotification(
        "error",
        "warning",
        "Notificación",
        `Ocurrió un error en el perfil: ${e.message}`,
        "",
        true
      );
      showNotificationError(`Ocurrió un error en el perfil: ${e.message}`);
    }
    setLoad(false);
  };

  useEffect(async () => {
    const countries = await getCountriesData();
    await getJsonData(countries);
  }, [pathname]);

  const { columns, rows } = data(jsonData, updateUploadDocPermJsonData);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDTypography variant="subtitle" fontWeight="bold">
              Permite activar e inactivar la carga masiva e individual de documentos en el UPLOAD
              para el perfil receptor
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDBox pt={3} pb={3} component="form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card>
                    <MDBox sx={{ position: "relative", mx: 3, py: 2, px: 2 }}>
                      <Grid
                        container
                        spacing={3}
                        justifyContent="left"
                        alignItems="left"
                        height="100%"
                      >
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                          <Autocomplete
                            value={newValuePais}
                            onChange={(event, newValue) => {
                              setNewValuePais(newValue);
                            }}
                            options={selectData.pais}
                            getOptionLabel={(option) => option.nombre}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={(params) => (
                              <MDInput
                                variant="standard"
                                type="text"
                                label="Pais"
                                fullWidth
                                required
                                InputLabelProps={{ shrink: true }}
                                {...params}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                          <MDInput
                            variant="standard"
                            type="text"
                            label="Tax ID"
                            fullWidth
                            required
                            InputLabelProps={{ shrink: true }}
                            value={newValueTaxId}
                            onChange={(e) => {
                              setNewValueTaxId(e.target.value);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                          <MDBox
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                            width={{ xs: "100%", sm: "auto" }}
                            mt={{ xs: 1, sm: 0 }}
                          >
                            <MDBox lineHeight={0} mx={2}>
                              <MDTypography variant="button" color="text">
                                Carga de documentos
                              </MDTypography>
                            </MDBox>
                            <MDBox mr={1}>
                              <Switch
                                checked={newValueLoadDocUpload}
                                onChange={() => setFuncionalities()}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            </MDBox>
                          </MDBox>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="left" alignItems="left" height="100%">
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={10} />
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={4}
                      xl={2}
                      container
                      justifyContent="flex-end"
                    >
                      <MDButton
                        variant="contained"
                        type="submit"
                        sx={{
                          background: "linear-gradient(45deg, #099268 30%, #099268 90%)",
                          color: "#FFFFFF",
                        }}
                      >
                        <Icon>save</Icon>&nbsp;Guardar
                      </MDButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox>
                <DataTable
                  entriesPerPage={(false, { defaultValue: 10, entries: [5, 10, 25, 50] })}
                  canSearch
                  showTotalEntries
                  table={{ columns, rows }}
                  pagination={{ variant: "contained", color: "warning" }}
                  isSorted
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderNotificacion}
      {loading}
      <Footer />
    </DashboardLayout>
  );
}

export default ConfiguracionGeneralSS;
