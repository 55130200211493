/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router components
import { useLocation, Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import AutorenewIcon from "@mui/icons-material/Autorenew";

// Material Dashboard 2 React components
import { Autocomplete } from "@mui/material";
import MDBox from "../../../../components/MDBox";
import MDInput from "../../../../components/MDInput";
import MDTypography from "../../../../components/MDTypography";
import BKModalReleaseNote from "../../../../components/BKModalReleaseNote";
import BKModalConfirm from "../../../../components/BKModalConfirm";

// Material Dashboard 2 React example components
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../../examples/Footer";
import NewDataTable from "../../../../examples/Tables/NewDataTable";

// Data
import data from "./data";

// API
import xPosApiRequest from "../../../../api/xPosApiRequest";
import { isOk } from "../_helpers/requestHelpers";
import Notification from "../_components/shared/Notification";
import useNotification from "../_hooks/useNotification";
import typeProducts from "../_constants/versiones";

function Versiones() {
  const [downloadProgress, setDownloadProgress] = useState({});
  const { notification, showNotificationError, showNotificationSuccess, setNotification } =
    useNotification();
  const [jsonData, setJsonData] = useState([]);
  const [filteredData, setFilteredData] = useState(undefined);

  const [searchTypeProduct, setSearchTypeProduct] = useState([]);
  const { pathname } = useLocation();

  const [modal, setModal] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [version, setVersion] = useState("");
  const [deleteVersion, setDeleteVersion] = useState();
  const [deleteModal, setDeleteModal] = useState(false);

  const [reloadTable, setReloadTable] = useState(0);

  const openAddNewElementModal = (element) => {
    setVersion(element);
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const handleCopy = (event, value) => {
    if (typeof value === "string") {
      navigator.clipboard.writeText(value.toString());
    } else {
      navigator.clipboard.writeText(value.props.children);
    }
    showNotificationSuccess("El elemento se ha copiado exitosamente");
  };

  const downloadJsonData = (releaseNote) => {
    if (releaseNote) {
      const txtContent = `data:text/csv;charset=utf-8,${releaseNote}`;
      const encodedUri = encodeURI(txtContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "Release Note.txt");
      document.body.appendChild(link);
      link.click();
      showNotificationSuccess("El archivo se ha descargado exitosamente");
    } else {
      showNotificationError("Error al descargar el archivo");
    }
  };

  const renderModal = (
    <BKModalReleaseNote closeModal={closeModal} open={modal} title="Release Note">
      <MDBox mb={1} sx={{ position: "relative" }}>
        <Grid mx={2} display="flex" justifyContent="space-between">
          <MDTypography
            style={{
              display: "flex",
              justifyContent: "flex-start",
              color: "#344767",
              fontSize: 15,
            }}
          >
            Versión xPOS: {version.versionNumber}
          </MDTypography>
          <Grid hidden={version.releaseNote === ""}>
            <Tooltip title="Descargar" disableInteractive>
              <Icon
                fontSize="small"
                style={{ color: "#344767", cursor: "pointer", marginRight: 5 }}
                onClick={() => downloadJsonData(version.releaseNote)}
              >
                download
              </Icon>
            </Tooltip>
            <Tooltip title="Copiar" disableInteractive>
              <Icon
                fontSize="small"
                style={{ color: "#344767", cursor: "pointer" }}
                onClick={(event) => handleCopy(event, version.releaseNote)}
              >
                copy
              </Icon>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" alignItems="center" height="100%">
          <Grid style={{ margin: "1em", marginTop: 0, width: "400px" }}>
            <TextField
              multiline
              rows={10}
              value={
                version.releaseNote !== "" ? version.releaseNote : "No existe registro de cambios"
              }
              disabled
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </MDBox>
    </BKModalReleaseNote>
  );

  const fetchData = async () => {
    setLoadingTable(true);
    const options = {
      method: "GET",
      url: "/version",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await xPosApiRequest(options);
      const versionData = await response.data;
      setJsonData(versionData);
      setLoadingTable(false);
    } catch (error) {
      showNotificationError(error.message);
      setLoadingTable(false);
    }
  };

  const handleDownload = async (guid) => {
    if (guid !== undefined) {
      const options = {
        method: "GET",
        url: `/version/${guid}/release`,
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "blob",
      };

      setDownloadProgress({ ...downloadProgress, [guid]: 0 });
      const timer = setInterval(() => {
        setDownloadProgress((prevProgress) => {
          if (prevProgress[guid] >= 15) {
            clearInterval(timer);
            return prevProgress;
          }
          return { ...prevProgress, [guid]: prevProgress[guid] + 1.5 };
        });
      }, 800);

      try {
        const response = await xPosApiRequest(options, {
          onDownloadProgress: (progressEvent) => {
            let percentCompleted = Math.max(
              Math.round((progressEvent.loaded * 100) / progressEvent.total),
              15
            );
            if (percentCompleted === 100) percentCompleted = 0;
            setDownloadProgress((oldProgress) => ({
              ...oldProgress,
              [guid]: oldProgress[guid] === 100 ? 0 : percentCompleted,
            }));
          },
        });
        if (isOk(response.status)) {
          const blob = new Blob([response.data]);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "VersionXpos.zip");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
          showNotificationSuccess(`El archivo se ha descargado exitosamente`);
        }
      } catch (error) {
        showNotificationError(`Error al descargar`);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [pathname, reloadTable]);

  const closeConfirmDeleteModal = () => {
    setDeleteModal(false);
    setDeleteVersion(undefined);
  };

  const handleDeleteVersion = (versionXPOS) => {
    setDeleteVersion(versionXPOS);
    // setOrgName(pool.name);
    setDeleteModal(true);
  };

  const setDeleteJsonData = async () => {
    if (deleteVersion !== undefined) {
      const options = {
        method: "PUT",
        url: `/version/${deleteVersion}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      try {
        const response = await xPosApiRequest(options);
        if (isOk(response.status)) {
          fetchData();
          showNotificationSuccess(`Se eliminó la versión exitosamente.`);
          setReloadTable(reloadTable + 1);
        } else {
          showNotificationError(response.data);
        }
      } catch (error) {
        showNotificationError(`No se pudo eliminar la versión.`);
      }
      setDeleteModal(false);
    }
  };

  const renderConfirmDeleteModal = (
    <BKModalConfirm
      closeModal={closeConfirmDeleteModal}
      saveChanges={setDeleteJsonData}
      open={deleteModal}
      title="Bloquear versión"
      titleButton="Confirmar"
    >
      <MDBox px={2} sx={{ position: "relative" }}>
        <Grid container justifyContent="center" alignItems="center" height="100%">
          <div style={{ marginTop: "2em", marginBottom: "1.5em" }}>
            ¿Esta seguro que desea bloquear esta versión?
          </div>
        </Grid>
      </MDBox>
    </BKModalConfirm>
  );

  const { columns, rows } = data(
    filteredData || jsonData,
    openAddNewElementModal,
    handleDownload,
    handleDeleteVersion,
    downloadProgress
  );

  const handleSearchTypeProduct = (typeProduct) => {
    setSearchTypeProduct(typeProduct);
    const filteredVersiones = jsonData.filter((vers) =>
      typeProduct.some((item) => item.value.toLowerCase() === vers.product?.toLowerCase())
    );

    setFilteredData(filteredVersiones.length > 0 ? filteredVersiones : undefined);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {renderConfirmDeleteModal}
      {renderModal}
      <MDTypography fontWeight="bold" variant="h5" color="dark" noWrap>
        Listado de versiones
      </MDTypography>
      <MDBox py={3}>
        <MDBox pt={3} pb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} py={1}>
              <MDBox display="flex" justifyContent="space-between">
                <MDBox display="flex" alignItems="center" justifyContent="flex-start">
                  <MDBox width="25rem" ml={2}>
                    <Autocomplete
                      multiple
                      options={typeProducts}
                      getOptionLabel={(option) => option.label}
                      size="medium"
                      value={searchTypeProduct}
                      onChange={(e, newElement) => handleSearchTypeProduct(newElement)}
                      renderInput={(params) => (
                        <MDInput
                          {...params}
                          label="Tipo"
                          placeholder="Tipo de producto"
                          variant="outlined"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </MDBox>
                </MDBox>

                <MDBox display="flex" alignItems="center" justifyContent="flex-end">
                  <Grid
                    component={Link}
                    to="/XPos/Versiones/CrearVersion"
                    variant="outlined"
                    size="medium"
                    width="180px"
                    borderRadius={2}
                    style={{
                      backgroundColor: "#1971c2",
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <AutorenewIcon style={{ cursor: "pointer", color: "white" }} />
                    <MDTypography
                      fontWeight="bold"
                      color="white"
                      py={1}
                      style={{ marginLeft: 5, paddingRight: 5, fontSize: 12 }}
                    >
                      ACTUALIZAR VERSIÓN
                    </MDTypography>
                  </Grid>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <MDBox>
                  <NewDataTable
                    entriesPerPage={(false, { defaultValue: 10 })}
                    canSearch
                    showTotalEntries
                    table={{ columns, rows }}
                    loadingTable={loadingTable}
                    pagination={{ variant: "contained", color: "warning" }}
                    isSorted
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Notification notification={notification} setNotification={setNotification} />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Versiones;
