/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import axios from "axios";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useState } from "react";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import { Box, Menu, MenuItem, IconButton } from "@mui/material";

// Icon from assets
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import BlockIcon from "@mui/icons-material/Block";

// Default Components
import MDProgress from "components/MDProgress";
import MDTypography from "../../../../../components/MDTypography";

export default function data(
  jsonData,
  openAddNewElementModal,
  handleDownload,
  deleteJsonData,
  downloadProgress
) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuActive, setMenuActive] = useState("");
  const userRol = JSON.parse(sessionStorage.getItem("userType"));

  const handleClick = (id, event) => {
    setMenuActive(id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setMenuActive("");

  const getDate = (date) => `${date.split("T")[0]} ${date.split("T")[1]}`;

  const truncateText = (text, maxLength = 30) =>
    text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

  const getRows = () => {
    const elements = [];

    for (let pos = 0; pos < jsonData.length; pos += 1) {
      const menuId = (action) => `user-menu-${action}-${jsonData[pos].guid}`;
      const versionDetailGuid = jsonData[pos]?.versionDetails?.find((detail) =>
        Object.keys(downloadProgress).includes(detail.guid)
      )?.guid;

      const progressValue = versionDetailGuid ? downloadProgress[versionDetailGuid] : null;

      elements.push({
        versionXPOS: jsonData[pos].versionNumber,
        fechaActualizacion: getDate(jsonData[pos]?.updatedAt ?? jsonData[pos]?.createdAt),
        producto: jsonData[pos].product.toLowerCase(),
        descripcion: truncateText(jsonData[pos].releaseNote),
        action: progressValue ? (
          <Box style={{ width: "100%", height: "100%" }}>
            <MDProgress
              style={{ width: "70px" }}
              width="100%"
              variant="determinate"
              value={progressValue}
              color="warning"
            />
          </Box>
        ) : (
          <MDTypography
            color="text"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap="4px"
          >
            <Tooltip style={{ cursor: "pointer" }} title="Descargar" disableInteractive>
              <IconButton
                aria-controls={menuId("download")}
                aria-haspopup="true"
                color="inherit"
                onClick={(event) => handleClick(menuId("download"), event)}
                style={{
                  minWidth: "24px",
                  padding: "0px",
                }}
              >
                <FileDownloadIcon
                  size="20px"
                  fontSize="20px"
                  style={{ fontSize: "20px", cursor: "pointer" }}
                />
              </IconButton>
            </Tooltip>
            <Menu
              id={menuId("download")}
              anchorEl={anchorEl}
              open={menuActive === menuId("download")}
              onClose={() => handleClose()}
            >
              {jsonData[pos]?.versionDetails?.map((item) => (
                <MenuItem
                  className="MuiMenuItem-root"
                  key={item.guid}
                  onClick={() => handleDownload(item.guid)}
                >
                  {item.platform.toLowerCase()}
                </MenuItem>
              ))}
            </Menu>
            <Tooltip title="Release note" disableInteractive style={{ cursor: "pointer" }}>
              <IconButton
                color="inherit"
                onClick={() => {
                  openAddNewElementModal(jsonData[pos]);
                }}
                style={{
                  minWidth: "24px",
                  padding: "0px",
                }}
              >
                <ManageSearchIcon
                  size="22px"
                  fontSize="22px"
                  style={{ fontSize: "22px", cursor: "pointer" }}
                />
              </IconButton>
            </Tooltip>
            {userRol === 1 && (
              <>
                <Tooltip style={{ cursor: "pointer" }} title="Bloquear" disableInteractive>
                  <IconButton
                    aria-controls={menuId("block")}
                    aria-haspopup="true"
                    color="inherit"
                    onClick={(event) => handleClick(menuId("block"), event)}
                    style={{
                      minWidth: "24px",
                      padding: "0px",
                    }}
                  >
                    <BlockIcon
                      size="18px"
                      fontSize="18px"
                      style={{ fontSize: "18px", cursor: "pointer" }}
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  id={menuId("block")}
                  anchorEl={anchorEl}
                  open={menuActive === menuId("block")}
                  onClose={() => handleClose()}
                >
                  {jsonData[pos]?.versionDetails?.map((item) => (
                    <MenuItem
                      className="MuiMenuItem-root"
                      key={item.guid}
                      onClick={() => deleteJsonData(item.guid)}
                    >
                      {item.platform.toLowerCase()}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
          </MDTypography>
        ),
      });
    }
    return elements;
  };

  return {
    columns: [
      { Header: "Versión xPOS", accessor: "versionXPOS", align: "center" },
      { Header: "Fecha actualización", accessor: "fechaActualizacion", align: "center" },
      { Header: "Producto", accessor: "producto", align: "center" },
      { Header: "Descripción", accessor: "descripcion", align: "center" },
      { Header: "Acciones", accessor: "action", align: "center" },
    ],

    rows: getRows(),
  };
}

// Setting default values for the props of MDBadge
data.defaultProps = {
  jsonData: [],
};

// Typechecking props of the data
data.propTypes = {
  jsonData: PropTypes.objectOf(PropTypes.array),
  deleteJsonData: PropTypes.func.isRequired,
  handleDownload: PropTypes.func.isRequired,
  openAddNewElementModal: PropTypes.func.isRequired,
};
